import React, { useMemo } from 'react';
import { ActionSheet } from 'antd-mobile';
import type { Action } from 'antd-mobile/es/components/action-sheet';

import css from './SuccessPopup.module.scss';

type Props = {
  isVisible: boolean;
  onCancel: () => void;
};

const SuccessPopup = ({ isVisible, onCancel }: Props) => {
  const actions: Action[] = useMemo(() => [{ text: 'Отлично', key: 'close', onClick: onCancel }], [onCancel]);

  return (
    <ActionSheet
      extra="Товар добавлен в корзину"
      visible={isVisible}
      actions={actions}
      onClose={onCancel}
      className={css.popup}
      data-name="successPopup"
      popupClassName={css.popupContainer}
    />
  );
};

export default SuccessPopup;
