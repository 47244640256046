import React from 'react';
import { ReactComponent as Logo } from 'images/logo.svg';

import css from './Footer.module.scss';

const Footer = () => (
  <div className={css.container}>
    <span>Сделано с </span>
    <a
      href={process.env.REACT_APP_LANDING_URL || '#'}
      title="Jiff Food"
      target="_blank"
      className={css.link}
      rel="noreferrer"
    >
      JIFF
    </a>
    <Logo />
  </div>
);

export default Footer;
