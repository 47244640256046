// import { mockedAxios as axios } from 'utils/axios';
import axios from 'utils/axios';
import { Menu } from 'entities/menu';

const getMenu = (pointSlug: string) =>
  axios.get<Menu>('/products/public', {
    params: {
      pointSlug,
      page: 1,
      perPage: 25
    }
  });

export default getMenu;
