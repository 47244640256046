import { useCallback, useEffect, useRef, useState } from 'react';

const useSizes = () => {
  const [sizes, setSizes] = useState({ width: window.innerWidth, height: window.innerHeight });

  const sizesRef = useRef(sizes);

  useEffect(() => {
    sizesRef.current = sizes;
  }, [sizes]);

  const resizeHandler = useCallback(() => {
    if (sizesRef.current.width !== window.innerWidth || sizesRef.current.height !== window.innerHeight) {
      setSizes({ width: window.innerWidth, height: window.innerHeight });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return sizes;
};

export default useSizes;
