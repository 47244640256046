import React, { memo } from 'react';
import { Category } from '../../entities/menu';
import DishList from '../../components/DishList';

type Props = {
  categories: Category[];
};

const VirtualizedCategories = ({ categories }: Props) => (
  <>
    {categories.map((category) => (
      <DishList
        key={category.categoryId}
        categoryId={category.categoryId}
        title={category.categoryName}
        items={category.items}
      />
    ))}
  </>
);

export default memo(VirtualizedCategories);
