import React from 'react';

import { NavBar as NavBarComponent } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import css from './Header.module.scss';

type Props = {
  title: string;
  backArrowShown?: boolean;
  forwardArrowShown?: boolean;
  errorPage?: boolean;
};

function Header({ title, backArrowShown = false, forwardArrowShown = false, errorPage = false }: Props) {
  const navigate = useNavigate();

  const goBack = () => {
    if (errorPage) {
      navigate('../');
    } else if (window.history.state.idx === 0) {
      // if the page was opened from direct link
      navigate('../');
    } else {
      navigate(-1);
    }
  };

  return (
    <header className={`${css.container} ${!backArrowShown && !forwardArrowShown && css.main}`}>
      <NavBarComponent onBack={goBack} backArrow={backArrowShown}>
        {title}
      </NavBarComponent>
    </header>
  );
}

export default Header;
