import React, { memo, useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dish } from '../../../entities/menu';
import { DishGrid } from '../../../components';
import { useAppThunkDispatch } from '../../../utils/hooks';
import { setItemGridRowHeight } from '../../../redux/layout/actions';

import css from './DishRowMeasurer.module.scss';
import { selectMenuConfig } from '../../../redux/menu/selectors';

const dishes: Dish[] = [
  {
    id: 22,
    categoryId: 101,
    name: 'Томатный крем-суп',
    isHidden: false,
    recommendedProductIds: [],
    modifierIds: [],
    sizes: [{ id: 127, name: 'Стандарт', isDefault: true, price: 420 }],
    promoIds: [],
    weight: '300',
    ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
    description:
      'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
    image: 'https://picsum.photos/400/400'
  }
];

const DishRowMeasurer = () => {
  const dispatch = useAppThunkDispatch();
  const rowRef = React.useRef<HTMLDivElement>(null);

  const menuConfig = useSelector(selectMenuConfig);

  const measure = useCallback(() => {
    if (rowRef.current) {
      dispatch(setItemGridRowHeight(rowRef.current.getBoundingClientRect().height));
    }
  }, [dispatch]);

  useLayoutEffect(() => {
    measure();

    if (rowRef.current) {
      window.addEventListener('resize', measure);
    }

    return () => {
      window.removeEventListener('resize', measure);
    };
  }, [dispatch, measure, menuConfig]);

  return (
    <div ref={rowRef} className={css.container} id="grid-item-measurer">
      <DishGrid items={dishes} />
    </div>
  );
};

export default memo(DishRowMeasurer);
