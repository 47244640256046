import React, { useMemo, memo, useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { CategoryItem } from '../../entities/menu';
import RecommendationItem from '../RecommendationItem';

import css from './DishesSwiper.module.scss';
import { selectMenuConfig } from '../../redux/menu/selectors';

type Props = {
  items: CategoryItem[];
  onDishClick: (id: number) => void;
};

const DishesSwiper = ({ items, onDishClick }: Props) => {
  const menuConfig = useSelector(selectMenuConfig);
  const [trackMouse, setTrackMouse] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          left: (scrollContainerRef.current.scrollLeft || 0) - e.movementX
        });
      }
    };

    const removeMouse = () => {
      setTrackMouse(false);
    };

    if (trackMouse) {
      window.addEventListener('mousemove', handler);
      window.addEventListener('mouseup', removeMouse);
    }

    return () => {
      window.removeEventListener('mousemove', handler);
      window.removeEventListener('mouseup', removeMouse);
    };
  }, [trackMouse]);

  const dishList = useMemo(
    () =>
      items.map((item) => (
        <div
          className={cn(css.swiperSlide, {
            [css.swiperSlideWithImage]: menuConfig.menuIsImageShown
          })}
          key={item.id}
          onClick={() => onDishClick(item.id)}
        >
          <RecommendationItem item={item} />
        </div>
      )),
    [items, menuConfig.menuIsImageShown, onDishClick]
  );

  return (
    <div
      className={cn(css.swiperWrapper, {
        [css.swiperWrapperWithImage]: menuConfig.menuIsImageShown
      })}
    >
      <div
        ref={scrollContainerRef}
        onMouseDown={() => setTrackMouse(true)}
        onMouseUp={() => setTrackMouse(false)}
        className={cn(css.swiper, {
          [css.swiperWithImage]: menuConfig.menuIsImageShown
        })}
      >
        {dishList}
      </div>
    </div>
  );
};

export default memo(DishesSwiper);
