import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import { selectPointName } from '../../redux/menu/selectors';

const SentryWatcher = () => {
  const pointName = useAppSelector(selectPointName);
  const { pointSlug } = useParams();

  useEffect(() => {
    if (!pointSlug) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        pointName,
        pointSlug
      });
    }
  }, [pointName, pointSlug]);

  return null;
};

export default SentryWatcher;
