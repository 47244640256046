import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import 'utils/scss/indents.scss';
import 'utils/scss/layout.scss';
import 'utils/scss/typography.scss';

import 'utils/axios/mocks';

import Router from './router';

import store from './redux/store';
import { SentryWatcher } from './components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Sentry.init({
  // TODO Later it can be moved to .env
  dsn: 'https://146480c9efa143b4a571ee5cbce83bb4@o1346460.ingest.sentry.io/6624375',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production' && !process.env.CI
});

root.render(
  <Provider store={store}>
    <SentryWatcher />
    <Router />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
