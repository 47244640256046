import React from 'react';
import { Button } from 'antd-mobile';
import { DeleteOutlined } from '@ant-design/icons';

import css from './ClearButton.module.scss';

type Props = {
  onClick: () => void;
};

const ClearButton = ({ onClick }: Props) => (
  <div className={css.container}>
    <Button onClick={onClick} className={css.button} data-name="clearButton">
      <DeleteOutlined />
    </Button>
  </div>
);

export default ClearButton;
