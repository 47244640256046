import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchBar } from 'antd-mobile';

import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { searchDishesAction, setSearchString } from 'redux/search/actions';
import { selectSearchString } from 'redux/search/selectors';

import css from './DishesSearch.module.scss';

const DishesSearch = () => {
  const dispatch = useAppThunkDispatch();
  const searchString = useAppSelector(selectSearchString);
  const [searchInFocus, setSearchInFocus] = useState<boolean>(false);

  const isCancelButtonActive = useMemo(() => searchInFocus || searchString.length > 0, [searchString, searchInFocus]);

  const timerRef = useRef<number | undefined>(undefined);

  const onSearchInputChange = useCallback(
    (value: string) => {
      dispatch(setSearchString(value));
    },
    [dispatch]
  );

  useEffect(() => {
    clearTimeout(timerRef.current);

    if (searchString.length === 0) {
      dispatch(searchDishesAction());
    } else {
      timerRef.current = setTimeout(() => dispatch(searchDishesAction()), 300) as unknown as number;
    }
  }, [dispatch, searchString]);

  return (
    <div className={css.searchContainer}>
      <SearchBar
        placeholder="Помочь найти блюдо"
        cancelText="Отмена"
        showCancelButton={() => isCancelButtonActive}
        onChange={onSearchInputChange}
        onFocus={() => setSearchInFocus(true)}
        onBlur={() => setSearchInFocus(false)}
        className={css.searchBar}
        value={searchString}
        data-name="searchBar"
      />
    </div>
  );
};

export default DishesSearch;
