import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getVisitedRoutes = () => JSON.parse(sessionStorage.getItem('visitedRoutes') || '{}') as Record<string, true>;
const setVisitedRoutes = (nextData: Record<string, true>) =>
  sessionStorage.setItem('visitedRoutes', JSON.stringify(nextData));

const useScrollTopOnFirstRender = (deps: any[] = []) => {
  const location = useLocation();

  useLayoutEffect(() => {
    const visitedRoutes = getVisitedRoutes();

    // Scroll to top only for visited routes
    // In this case any page opened again will have new key
    // but previous pages would have the same key
    // which can help us to avoid "scroll to top" when user is going back from dish page to promo
    if (!visitedRoutes[location.key]) {
      setTimeout(() => {
        window.scrollTo({
          // Fix to 1 avoid issue in safari with native browser header
          top: 1,
          behavior: 'auto'
        });
      }, 0);
    }

    visitedRoutes[location.key] = true;
    setVisitedRoutes(visitedRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useScrollTopOnFirstRender;
