import React, { useMemo } from 'react';
import { chunk } from 'lodash';

import { CategoryItem } from 'entities/menu';
import VirtualizedChunks from './VirtualizedChunks';

import css from './DishList.module.scss';

type Props = {
  title: string;
  items: CategoryItem[];
  categoryId: number;
};

const DishList = ({ title = '', items, categoryId }: Props) => {
  const itemsChunks = useMemo(() => chunk(items, 8), [items]);

  return (
    <div className={css.container} id={`category-${categoryId}`} data-name={`categoryItem-${categoryId}`}>
      <h1 className={css.categoryTitle}>{title}</h1>
      <div className={css.chunksContainer}>
        {itemsChunks.map((itemsChunk, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <VirtualizedChunks key={index} index={index} items={itemsChunk} />
        ))}
      </div>
      <div
        className={css.scrollAnchor}
        id={`category-${categoryId}-scroll-anchor`}
        data-category={`category-${categoryId}`}
      />
    </div>
  );
};

export default DishList;
