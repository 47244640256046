import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Divider, Skeleton, SpinLoading } from 'antd-mobile';

import { setDishIdForOrderPopup, setRecommendedDishIdForOrderPopup } from 'redux/layout/actions';
import { selectCurrentMenu, selectMenuLoading, selectPointName } from 'redux/menu/selectors';
import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { LoadingMenu, LoadingPromo, PromosSwiper, Footer, DishNotFound, CartButton } from 'components';
import { useSelector } from 'react-redux';
import { selectLoadingState, selectPromos } from 'redux/promo/selectors';
import { selectSearchState } from 'redux/search/selectors';
import { ScrollProvider } from 'utils/hooks/useScrollContext';
import VirtualizedCategories from './VirtualizedCategories';
import DishRowMeasurer from './DishRowMeasurer/DishRowMeasurer';
import DishesSearch from './DishesSearch';
import CategoriesTabs from './CategoriesTabs';

import css from './HomePage.module.scss';

function HomePage() {
  const dispatch = useAppThunkDispatch();
  const { pathname } = useLocation();
  const [activeTabKey, setActiveTabKey] = useState<string | null>(null);
  const [scrollIsActive, setScrollIsActive] = useState<boolean>(false);
  const { string, isLoading: isSearchLoading } = useAppSelector(selectSearchState);

  const menu = useAppSelector(selectCurrentMenu);
  const pointName = useAppSelector(selectPointName);
  const promos = useAppSelector(selectPromos);
  const { loading: isMenuLoading, loaded: isMenuLoaded } = useSelector(selectMenuLoading);
  const { loading: isPromoLoading } = useAppSelector(selectLoadingState);

  const filledCategories = useMemo(() => menu.filter((category) => category.items.length > 0), [menu]);

  useEffect(() => {
    dispatch(setDishIdForOrderPopup(undefined));
    dispatch(setRecommendedDishIdForOrderPopup(undefined));
  }, [pathname, dispatch]);

  useEffect(() => {
    if (filledCategories.length) {
      setActiveTabKey(`category-${filledCategories[0].categoryId}`);
    }
  }, [filledCategories]);

  const setActiveTabWithScroll = useCallback((nextActiveTabKey: string, withScroll = true) => {
    const activeTab = document.getElementById(`${nextActiveTabKey}-scroll-anchor`);

    setActiveTabKey(nextActiveTabKey);
    if (activeTab && withScroll) {
      const boundRect = activeTab.getBoundingClientRect();

      setScrollIsActive(true);

      // Immediately scroll to the active tab if distance is greater than screen height
      if (boundRect.top > 0 && boundRect.top > window.innerHeight) {
        const scrollByValue = boundRect.top - window.innerHeight;
        window.scrollBy(0, scrollByValue);
      }

      // Immediately scroll to the active tab if distance is greater than screen height
      if (boundRect.top < 0 && boundRect.top + window.innerHeight < 0) {
        const scrollByValue = boundRect.top + window.innerHeight;
        window.scrollBy(0, scrollByValue);
      }
      scrollIntoView(activeTab, {
        block: 'start',
        behavior: 'smooth'
      }).then(() => {
        setScrollIsActive(false);
      });
    }
  }, []);

  const PromoBlock = useMemo(() => {
    if (isPromoLoading) {
      return (
        <div className={css.promoWrapper}>
          <LoadingPromo />
        </div>
      );
    }
    if (promos.ids.length) {
      return (
        <div className={css.promoWrapper}>
          <PromosSwiper itemsIds={promos.ids} />
        </div>
      );
    }

    return null;
  }, [isPromoLoading, promos.ids]);

  const mainContent = useMemo(() => {
    if (isMenuLoading) {
      return (
        <div className="mb-16">
          <LoadingMenu />
        </div>
      );
    }

    if (string.length > 0) {
      if (string.length < 3) {
        return <p className={css.searchTip}>Продолжайте вводить символы, мы сделаем поиск более точным</p>;
      }

      if (isSearchLoading) {
        return <SpinLoading color="primary" className="margin-horizontal-auto" />;
      }

      if (menu.length === 0) {
        return <DishNotFound />;
      }
    }

    return (
      <>
        {string.length > 0 && <h2 className={css.searchResultsTitle}>Вот что нашли для вас</h2>}
        <CategoriesTabs
          categories={filledCategories}
          activeKey={activeTabKey!}
          onChange={setActiveTabWithScroll}
          scrollIsActive={scrollIsActive}
        />
        <VirtualizedCategories categories={filledCategories} />
      </>
    );
  }, [
    activeTabKey,
    filledCategories,
    isMenuLoading,
    isSearchLoading,
    menu.length,
    scrollIsActive,
    setActiveTabWithScroll,
    string.length
  ]);

  useLayoutEffect(() => {
    if (isMenuLoaded) {
      requestAnimationFrame(() => {
        const { scrollHeight } = document.body;
        sessionStorage.setItem('lastKnownScrollHeight', `${scrollHeight}px`);
      });
    }
  }, [isMenuLoaded]);

  const styledForLastKnownHeight = useMemo(() => {
    const lastKnownScrollHeight = sessionStorage.getItem('lastKnownScrollHeight');

    if (!isMenuLoaded && lastKnownScrollHeight) {
      return {
        minHeight: lastKnownScrollHeight
      };
    }

    return undefined;
  }, [isMenuLoaded]);

  return (
    <ScrollProvider>
      <div className={css.container} style={styledForLastKnownHeight}>
        <DishRowMeasurer />
        <Helmet>
          <title>{pointName}</title>
        </Helmet>

        {isMenuLoading ? (
          <Skeleton.Title animated className={css.loadingTitle} />
        ) : (
          <div className={css.headerContainer}>
            <h1 data-name="point-name" className={css.title}>
              {pointName}
            </h1>
            <div className={css.cartButton}>
              <CartButton />
            </div>
          </div>
        )}

        <Divider />

        <DishesSearch />

        {!string.length && PromoBlock}

        {mainContent}

        {!string.length && <Footer />}
      </div>
    </ScrollProvider>
  );
}

export default HomePage;
