// import { mockedAxios as axios } from 'utils/axios';
import axios from 'utils/axios';
import { StopListResponse } from 'entities/stopList';

const getStopList = (pointSlug: string) =>
  axios.get<StopListResponse>('/stop-list', {
    params: {
      pointSlug
    }
  });

export default getStopList;
