import React from 'react';
import { noop } from 'lodash';

import { useDefaultPrice } from 'utils/hooks';
import { CategoryItem } from 'entities/menu';
import ImageLazyLoad from 'components/ImageLazyLoad';
import OpenOrderPopupButton from 'components/OpenOrderPopupButton';

import css from './ItemWithImage.module.scss';

type Props = {
  item: CategoryItem;
};

const ItemWithImage = ({ item }: Props) => {
  const price = useDefaultPrice(item);

  return (
    <div className={css.item} data-name={`recommendedDishItem-${item.id}`}>
      <div className={css.imageContainer}>
        <ImageLazyLoad image={item.image} threshold={0} visibleByDefault />
      </div>

      <h3 className={css.title}>{item.name}</h3>
      <div className={css.bottomBlock}>
        <div>
          <span className={css.price}>{price}&#x20bd;</span>
          <span className={css.weight}>{item.weight}</span>
        </div>
        <OpenOrderPopupButton onClick={noop} />
      </div>
    </div>
  );
};

export default React.memo(ItemWithImage);
