import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.stopList;

const selectStopList = createSelector([selectState], (state) => state.productIds);

const selectStopListLoading = createSelector([selectState], ({ loading, loaded }) => ({
  loading,
  loaded
}));

export { selectStopList, selectStopListLoading };
