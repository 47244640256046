import { createAction } from '@reduxjs/toolkit';

const setItemGridRowHeight = createAction<number>('layout/setItemGridRowHeight');

const setDishIdForOrderPopup = createAction<number | undefined>('layout/setDishIdForOrderPopup');

const setRecommendedDishIdForOrderPopup = createAction<number | undefined>('layout/setRecommendedDishIdForOrderPopup');

// TODO Maybe remove calls with the same value (false, false, false)
const toggleSuccessPopup = createAction<boolean>('layout/toggleSuccessPopup');

export { setItemGridRowHeight, setDishIdForOrderPopup, setRecommendedDishIdForOrderPopup, toggleSuccessPopup };
