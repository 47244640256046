import React from 'react';
import { Button, ErrorBlock, Space } from 'antd-mobile';

import css from './Error.module.scss';

type Props = {
  message: string;
  buttonText: string;
  onErrorButtonClick: () => void;
  dataName: string;
};

const Error = ({ message, onErrorButtonClick, buttonText, dataName }: Props) => (
  <Space block direction="vertical" className={css.container} data-name={dataName}>
    <ErrorBlock status="default" title={message} description="" fullPage />
    <Button color="primary" size="small" onClick={onErrorButtonClick} data-name={`${dataName}-button`}>
      {buttonText}
    </Button>
  </Space>
);

export default Error;
