import React from 'react';
import { Divider, Skeleton } from 'antd-mobile';

import css from './LoadingDishPage.module.scss';
import LoadingRecommendations from '../LoadingRecommendations';

const LoadingDishPage = () => (
  <div className={css.container}>
    <div className={css.imageContainer}>
      <Skeleton animated className={css.image} />
    </div>

    <div className={css.info}>
      <Skeleton.Title animated className={css.infoTitle} />

      <div className={css.flexContainer}>
        <Skeleton animated className={css.flexContainerItem} />
        <Skeleton animated className={css.flexContainerItem} />
      </div>

      <Skeleton.Paragraph lineCount={2} animated className={css.paragraph1} />
      <Divider />

      <div className={css.paragraph2}>
        <Skeleton.Paragraph lineCount={3} animated />
      </div>

      <div className={css.paragraph3}>
        <Skeleton.Paragraph lineCount={2} animated />
      </div>
    </div>

    <LoadingRecommendations />
  </div>
);

export default LoadingDishPage;
