import React, { useCallback } from 'react';

import { useAppThunkDispatch } from 'utils/hooks';
import { clearOrder } from 'redux/cart/actions';
import ClearCartPopup from '../ClearCartPopup';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

const ClearCartPopupContainer = ({ isVisible, onClose }: Props) => {
  const dispatch = useAppThunkDispatch();

  const onClearCart = useCallback(() => {
    dispatch(clearOrder());
    onClose();
  }, [dispatch, onClose]);

  const onCloseClearCartPopup = useCallback(() => onClose(), [onClose]);

  return <ClearCartPopup isVisible={isVisible} onSubmit={onClearCart} onCancel={onCloseClearCartPopup} />;
};

export default ClearCartPopupContainer;
