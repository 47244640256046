import Axios, { AxiosResponse } from 'axios';

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_BASE_URL
});

export type Response<T> = AxiosResponse<T>;
export default axios;
