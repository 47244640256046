import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ErrorBlock } from 'antd-mobile';
import { ReactComponent as EmptyCartImage } from 'images/empty-cart-image.svg';

import css from './EmptyOrder.module.scss';

const EmptyOrder = () => {
  const { pointSlug } = useParams();

  return (
    <div className={css.container} data-name="emptyOrder">
      <ErrorBlock
        status="empty"
        title="В корзине пока ничего нет"
        description="Добавьте блюда и они покажутся здесь"
        fullPage
        image={<EmptyCartImage className={css.image} />}
        className={css.emptyCartError}
      >
        <Link to={`/${pointSlug}`} className="link-no-styles">
          <Button fill="none" className={css.button}>
            Выбрать блюдо
          </Button>
        </Link>
      </ErrorBlock>
    </div>
  );
};

export default EmptyOrder;
