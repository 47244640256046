// import { mockedAxios as axios } from 'utils/axios';
import axios from 'utils/axios';
import { GetAllModifiersResponse } from 'entities/modifiers';

const getAllModifiers = async (pointSlug: string) =>
  axios.get<GetAllModifiersResponse>(`/modifiers/public`, {
    params: {
      pointSlug,
      page: 1,
      perPage: 25
    }
  });

export default getAllModifiers;
