import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { setSearchLoadingState, setSearchString } from './actions';

const initialState: InitialState = {
  string: '',
  isLoading: false
};

const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSearchString, (state, action) => {
      state.string = action.payload;
      state.isLoading = !!action.payload;
    })
    .addCase(setSearchLoadingState, (state, action) => {
      state.isLoading = action.payload;
    });
});

export default searchReducer;
