import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchStopList, setStopList } from './actions';

const initialState: InitialState = {
  productIds: [],
  loading: false,
  loaded: false
};

const stopListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setStopList, (state, action) => {
      state.productIds = action.payload;
    })
    .addCase(fetchStopList.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchStopList.fulfilled, (state) => {
      state.loading = false;
      state.loaded = true;
    })
    .addCase(fetchStopList.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    });
});

export default stopListReducer;
