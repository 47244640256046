import { Category, Dish, Menu } from 'entities/menu';
import mock from './mockAdapter';

const mockedCategories: Category[] = [
  {
    categoryId: 101,
    isHidden: false,
    categoryName: 'Супы',
    items: [
      {
        id: 11,
        name: 'Уха',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стaндaрт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image:
          'https://images.unsplash.com/photo-1560684352-8497838a2229?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1128'
      },
      {
        id: 12,
        name: 'Солянка',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description: 'Самый вкусный суп на свете',
        image: null
      },
      {
        id: 20,
        name: 'Грибной крем-суп',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image:
          'https://images.unsplash.com/photo-1610725663727-08695a1ac3ff?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064'
      },
      {
        id: 21,
        name: 'Борщ',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image:
          'https://images.unsplash.com/photo-1611068120813-eca5a8cbf793?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
      },
      {
        id: 22,
        name: 'Томатный крем-суп',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      },
      {
        id: 23,
        name: 'Минестроне',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      },
      {
        id: 24,
        name: 'Харчо',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: null
      }
    ]
  },
  {
    categoryId: 102,
    isHidden: false,
    categoryName: 'Мясо',
    items: [
      {
        id: 13,
        name: 'Стейк Рибай',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      },
      {
        id: 14,
        name: 'Медальоны из телятины',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      }
    ]
  },
  {
    categoryId: 103,
    isHidden: false,
    categoryName: 'Салаты',
    items: [
      {
        id: 15,
        name: 'Греческий',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      },
      {
        id: 16,
        name: 'Цезарь с курицей',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: null
      },
      {
        id: 19,
        name: 'Цезарь с креветками',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description: 'Это не суп, это салат.',
        image: 'https://picsum.photos/400/400'
      }
    ]
  },
  {
    categoryId: 104,
    isHidden: false,
    categoryName: 'Десерты',
    items: [
      {
        id: 17,
        name: 'Медовик',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      },
      {
        id: 18,
        name: 'Блинчики',
        isHidden: false,
        weight: '300',
        ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
        sizes: [
          {
            id: 1,
            name: 'Стандарт',
            price: 15000,
            isDefault: true
          }
        ],
        description:
          'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
        image: 'https://picsum.photos/400/400'
      }
    ]
  }
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockedMenu: Menu = {
  pointName: "Your Mom's Hot Buns",
  menu: mockedCategories,
  menuConfig: {
    menuView: 'card',
    menuGridColsCount: 2,
    menuIsDescriptionShown: true,
    menuIsImageShown: true
  }
};

const randomCategoriesCount = 10;
const randomDishesPerCategory = 20;
// const randomCategoriesCount = 3;
// const randomDishesPerCategory = 12;

const getRandomDish = (categoryId: number): Dish => {
  const id = Math.floor(Math.random() * 100000);

  return {
    id,
    categoryId,
    name: `Стейк Рибай ${id}`,
    isHidden: false,
    recommendedProductIds: [],
    modifierIds: [],
    sizes: [{ id: 127, name: 'Стандарт', isDefault: true, price: 420 }],
    promoIds: [1],
    weight: '300',
    ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
    description: 'So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
    image: 'https://picsum.photos/400/400'
  };
};

const getRandomCategory = (): Category => {
  const id = Math.floor(Math.random() * 100000);

  return {
    categoryId: id,
    isHidden: false,
    categoryName: `Категория ${Math.floor(Math.random() * 100000)}`,
    items: new Array(randomDishesPerCategory).fill(null).map(() => getRandomDish(id))
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const randomMenu = {
  pointName: "Your Mom's Hot Buns",
  menu: new Array(randomCategoriesCount).fill(null).map(getRandomCategory)
};

mock.onGet('/products/public').reply((config) => {
  const { pointId: id } = config.params;

  if (id === '111') {
    return [
      404,
      {
        errorMessage: 'Page Not Found'
      }
    ];
  }

  // return [200, randomMenu];

  return [200, mockedMenu];
});

mock.onGet(/\/products\/public\/\d+/).reply((config) => {
  const id = config!.url!.split('/')[3];

  let product;

  mockedCategories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.id === +id) {
        product = item;
      }
    });
  });

  if (product) {
    return [200, product];
  }

  return [404, { errorMessage: 'Page Not Found' }];
});
