import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { handleError } from 'utils/axios';
import { CartItem } from 'entities/cart';
import { getPointSlug } from 'utils/decorators';

const pointSlug = getPointSlug();

const setOrder = createAction<CartItem[]>('cart/setOrder');

const fetchOrder = createAsyncThunk<
  void,
  undefined,
  {
    rejectValue?: string;
  }
>('cart/fetchOrder', async (_, { rejectWithValue, dispatch }) => {
  try {
    const order = JSON.parse(localStorage.getItem(`order-${pointSlug}`) || '[]');

    await dispatch(setOrder(order));

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

const updateOrder = createAsyncThunk<
  void,
  CartItem,
  {
    rejectValue?: string;
  }
>('cart/updateOrder', async (dish, { rejectWithValue, dispatch }) => {
  try {
    const prevOrder = JSON.parse(localStorage.getItem(`order-${pointSlug}`) || '[]');
    const sameDishIndex = prevOrder.findIndex((item: CartItem) => item.id === dish.id);
    let newOrder = prevOrder;

    // TODO Maybe make it 3 different actions such as
    // Update dish
    if (sameDishIndex !== -1) {
      newOrder[sameDishIndex] = { ...dish };
    }

    // remove dish
    if (sameDishIndex !== -1 && dish.counter === 0) {
      newOrder.splice(sameDishIndex, 1);
    }

    // add dish
    if (sameDishIndex === -1 && dish.counter !== 0) {
      newOrder = [...newOrder, { ...dish }];
    }

    // TODO Make localstorage updater as a hook that just listen for order state and compares it with locastorage order
    // If there is any difference, update localStorage with order state
    localStorage.setItem(`order-${pointSlug}`, JSON.stringify(newOrder));

    await dispatch(setOrder(newOrder));

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

const clearOrder = createAsyncThunk<
  void,
  undefined,
  {
    rejectValue?: string;
  }
>('cart/clearOrder', async (_, { rejectWithValue, dispatch }) => {
  try {
    localStorage.setItem(`order-${pointSlug}`, JSON.stringify([]));

    await dispatch(setOrder([]));

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);

    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

export { setOrder, fetchOrder, updateOrder, clearOrder };
