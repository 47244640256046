import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd-mobile';
import { ArrowLeftOutlined } from '@ant-design/icons';

import css from './NavBar.module.scss';

type Props = {
  errorPage?: boolean;
};

const NavBar = ({ errorPage = false }: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (errorPage) {
      navigate('../');
    } else if (window.history.state.idx === 0) {
      // if the page was opened from direct link
      navigate('../');
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={css.container}>
      <Button onClick={goBack} className={css.button}>
        <ArrowLeftOutlined />
      </Button>
    </div>
  );
};

export default NavBar;
