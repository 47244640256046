import { ErrorBlock } from 'antd-mobile';
import React from 'react';
import Header from 'components/Header';

const PageNotFound = () => (
  <div>
    <Header title="Не найдено" backArrowShown errorPage />
    <ErrorBlock status="empty" title="Страница не найдена" description="" fullPage />
  </div>
);

export default PageNotFound;
