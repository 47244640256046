import { stopListApi } from 'api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { StopListProductIds } from 'entities/stopList';
import { handleError } from 'utils/axios';
import { getPointSlug } from 'utils/decorators';

const pointSlug = getPointSlug();

const setStopList = createAction<StopListProductIds>('menu/setStopList');

const fetchStopList = createAsyncThunk<
  void,
  undefined,
  {
    rejectValue?: string;
  }
>('stopList/fetchStopList', async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await stopListApi.getStopList(pointSlug);

    await dispatch(setStopList(data.items));

    localStorage.setItem(`stopList-${pointSlug}`, JSON.stringify(data.items));

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchStopList, setStopList };
