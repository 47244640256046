import { combineReducers } from '@reduxjs/toolkit';
import menuReducer from './menu/reducer';
import promoReducer from './promo/reducer';
import stopListReducer from './stopList/reducer';
import layoutReducer from './layout/reducer';
import searchReducer from './search/reducer';
import modifiersReducer from './modifiers/reducer';
import cartReducer from './cart/reducer';

const reducer = combineReducers({
  menu: menuReducer,
  promo: promoReducer,
  stopList: stopListReducer,
  layout: layoutReducer,
  search: searchReducer,
  modifiers: modifiersReducer,
  cart: cartReducer
});

export default reducer;
