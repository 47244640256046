import { useCallback, useMemo } from 'react';

import { CartItem as CartItemType } from 'entities/cart';
import { selectOrder } from 'redux/cart/selectors';
import { selectPreviewDishes } from 'redux/menu/selectors';
import { selectModifiers } from 'redux/modifiers/selectors';
import { selectStopList } from 'redux/stopList/selectors';
import { useAppSelector } from 'utils/hooks';
import { formatPrice } from 'utils/decorators';
import { selectInitialDataLoaded } from 'redux/commonSelectors';

type OrderItemState = {
  isDisabled: boolean;
  cost: number;
};

const useCart = () => {
  const order = useAppSelector(selectOrder);
  const stopList = useAppSelector(selectStopList);
  const previewDishes = useAppSelector(selectPreviewDishes);
  const modifiers = useAppSelector(selectModifiers);

  const isInitialDataLoaded = useAppSelector(selectInitialDataLoaded);

  const isDishDisabled = useCallback((dish: CartItemType) => stopList.includes(dish.dishId), [stopList]);

  const getOrderItemCost = useCallback(
    (dish: CartItemType) => {
      const { size: sizeId, dishId, modifiers: dishModifiers } = dish;

      if (!previewDishes[dishId]) {
        return 0;
      }

      const modifiersCost = dishModifiers.reduce((acc, id) => {
        if (!modifiers[id]) {
          return acc;
        }

        return acc + modifiers[id].price;
      }, 0);

      const dishPrice = previewDishes[dishId].sizes.find((item) => item.id === sizeId)!.price;

      return +formatPrice(modifiersCost + dishPrice);
    },
    [modifiers, previewDishes]
  );

  const [totalCost, cartItemsState] = useMemo(() => {
    if (!isInitialDataLoaded) {
      return [0, {}];
    }

    let newTotalCost = 0;

    const newCartItemsState = order.items.reduce((acc, dish) => {
      const cost = getOrderItemCost(dish) * dish.counter!;
      const isDisabled = isDishDisabled(dish);

      if (!isDisabled) {
        newTotalCost += cost;
      }

      return { ...acc, [dish.id!]: { cost, isDisabled } };
    }, {} as Record<string, OrderItemState>);

    return [newTotalCost, newCartItemsState];
  }, [getOrderItemCost, isDishDisabled, isInitialDataLoaded, order.items]);

  return { totalCost, cartItemsState };
};
export default useCart;
