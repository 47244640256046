import React from 'react';

import { CategoryItem } from 'entities/menu';

import { useSelector } from 'react-redux';
import { selectMenuConfig } from '../../redux/menu/selectors';

import CardDishItem from './CardDishItem';
import ListDishItem from './ListDishItem';

type Props = {
  item: CategoryItem;
  isDisabled: boolean;
  promoId?: number;
};

const DishItem = ({ item, isDisabled, promoId }: Props) => {
  const menuConfig = useSelector(selectMenuConfig);

  if (menuConfig.menuView === 'list') {
    return <ListDishItem item={item} isDisabled={isDisabled} promoId={promoId} />;
  }

  return <CardDishItem item={item} isDisabled={isDisabled} promoId={promoId} />;
};

export default React.memo(DishItem);
