import React, { useState } from 'react';

import { Swiper } from 'antd-mobile';

import { Link, useNavigate } from 'react-router-dom';
import ImageLazyLoad from 'components/ImageLazyLoad';
import css from './PromosSwiper.module.scss';
import { useAppSelector } from '../../utils/hooks';
import { selectPromos } from '../../redux/promo/selectors';

type Props = {
  itemsIds: number[];
  showPromoName?: boolean;
};

const PromosSwiper = ({ itemsIds, showPromoName = false }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { byId } = useAppSelector(selectPromos);

  const navigate = useNavigate();

  const activePromo = byId[itemsIds[currentIndex]];

  return (
    <div className={css.container}>
      <Swiper
        indicator={itemsIds.length < 2 ? () => null : undefined}
        className={css.swiperWrapper}
        onIndexChange={(index) => setCurrentIndex(index)}
      >
        {itemsIds.map((itemId) => {
          const item = byId[itemId];

          return (
            <Swiper.Item key={item.id} onClick={() => navigate(`../promo/${item.id}`)} className={css.itemContainer}>
              <ImageLazyLoad image={item.image} />
            </Swiper.Item>
          );
        })}
      </Swiper>
      {showPromoName && activePromo && (
        <div className={css.promoName}>
          <Link to={`../promo/${activePromo.id}`}>{activePromo.name}</Link>
        </div>
      )}
    </div>
  );
};

export default PromosSwiper;
