import React from 'react';
import { CapsuleTabs } from 'antd-mobile';

const LoadingTabs = () => {
  const tabs = ['1t', '2t', '3t'];

  return (
    <CapsuleTabs defaultActiveKey={null} activeKey={null}>
      {tabs.map((item) => (
        <CapsuleTabs.Tab title="" key={item} />
      ))}
    </CapsuleTabs>
  );
};

export default LoadingTabs;
