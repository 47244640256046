import React, { memo } from 'react';

import { ClearButton } from 'components';

import css from './ClearCartButtonContainer.module.scss';

type Props = {
  onButtonClick: () => void;
};

const ClearCartButtonContainer = ({ onButtonClick }: Props) => (
  <div className={css.container}>
    <ClearButton onClick={onButtonClick} />
  </div>
);

export default memo(ClearCartButtonContainer);
