import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ShoppingOutlined } from '@ant-design/icons';
import { Badge } from 'antd-mobile';

import { selectOrder } from 'redux/cart/selectors';
import { useAppSelector } from 'utils/hooks';

import css from './CartButton.module.scss';

const CartButton = () => {
  const { pointSlug } = useParams();
  const order = useAppSelector(selectOrder);

  const numberOfDishes = useMemo(() => order.items.reduce((acc, item) => acc + item.counter!, 0), [order]);

  return (
    <Link to={`/${pointSlug}/cart`} className="link-no-styles">
      <div className={css.cartContainer} data-name="cartButton">
        <Badge content={numberOfDishes} className={css.cartButton}>
          <ShoppingOutlined className={css.cartIcon} />
        </Badge>
      </div>
    </Link>
  );
};

export default CartButton;
