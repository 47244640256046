import React from 'react';
import { noop } from 'lodash';

import { useDefaultPrice } from 'utils/hooks';
import { CategoryItem } from 'entities/menu';
import OpenOrderPopupButton from 'components/OpenOrderPopupButton';

import css from './ItemWithoutImage.module.scss';

type Props = {
  item: CategoryItem;
};

const ItemWithoutImage = ({ item }: Props) => {
  const price = useDefaultPrice(item);

  return (
    <div className={css.item} data-name={`recommendedDishItem-${item.id}`}>
      <div>
        <h3 className={css.title}>{item.name}</h3>
        <span className={css.weight}>{item.weight}</span>
      </div>
      <div className={css.bottomBlock}>
        <span className={css.price}>{price}&#x20bd;</span>
        <OpenOrderPopupButton onClick={noop} />
      </div>
    </div>
  );
};

export default React.memo(ItemWithoutImage);
