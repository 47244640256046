import { createAsyncThunk } from '@reduxjs/toolkit';

import { promoApi } from 'api';
import { handleError } from 'utils/axios';
import { getPointSlug } from 'utils/decorators';
import { PromoItem } from 'entities/promo';
import { InitialState } from './types';

const pointSlug = getPointSlug();

const fetchAllPromos = createAsyncThunk<
  InitialState['items'],
  undefined,
  {
    rejectValue?: string;
  }
>('promo/fetchAllPromos', async (_, { rejectWithValue }) => {
  try {
    const { data } = await promoApi.getList(pointSlug);
    return {
      ids: data.items.map((item) => item.id),
      byId: data.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item
        }),
        {} as Record<number, PromoItem>
      )
    };
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchAllPromos };
