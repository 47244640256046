import React, { memo, useCallback, useMemo, useState } from 'react';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import NoImage from 'images/image-no_image.svg';
import getImagePath from '../../utils/getImagePath';
import { LoadingImage } from '../Shimmer';
import css from './ImageLazyLoad.module.scss';
import { useScrollContext } from '../../utils/hooks/useScrollContext';

type Props = LazyLoadImageProps & {
  image: string | null;
};

const loadedImagesList: Record<string, boolean> = {};

const ImageLazyLoad = ({ image, placeholder, threshold, className, onError, onLoad, ...rest }: Props) => {
  const scrollPosition = useScrollContext();
  // console.log({ scrollPosition });
  const [noImage, setNoImage] = useState(!image);
  const [imageLoaded, setImageLoaded] = useState(image ? loadedImagesList[image] : false);

  const onErrorHandler: React.ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      onError?.(event);
      setNoImage(true);
      if (image) {
        loadedImagesList[image] = false;
      }
    },
    [image, onError]
  );

  const onLoadHandler: React.ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      setImageLoaded(true);
      onLoad?.(event);

      const loadedDefaultImage = (event.target as HTMLImageElement).currentSrc.endsWith(NoImage);

      if (image && !loadedDefaultImage) {
        loadedImagesList[image] = true;
      }
    },
    [image, onLoad]
  );

  const resultImage = useMemo(() => {
    if (noImage || !image) {
      return NoImage;
    }

    return getImagePath(image);
  }, [image, noImage]);

  return (
    <>
      <LazyLoadImage
        {...rest}
        src={resultImage}
        useIntersectionObserver={false}
        scrollPosition={scrollPosition}
        placeholder={placeholder || <LoadingImage />}
        className={`${css.image} ${className}`}
        threshold={threshold || 300}
        onError={onErrorHandler}
        onLoad={onLoadHandler}
      />
      <div className={`${css.image} ${css.skeletonWrapper} ${!imageLoaded && css.skeletonWrapperActive}`}>
        <LoadingImage />
      </div>
    </>
  );
};

export default memo(ImageLazyLoad);
