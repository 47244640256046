import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, memo } from 'react';
import { Button, FloatingPanel, SafeArea } from 'antd-mobile';
import type { FloatingPanelRef } from 'antd-mobile/es/components/floating-panel';

import { getDishByIdSelector, selectMenuConfig, selectPreviewDishes } from 'redux/menu/selectors';
import { selectStopList } from 'redux/stopList/selectors';
import { fetchDish } from 'redux/menu/actions';
import { setRecommendedDishIdForOrderPopup } from 'redux/layout/actions';
import { useAppSelector, useAppThunkDispatch, useDefaultPrice, useOrderItem, useSizes } from 'utils/hooks';
import ImageLazyLoad from 'components/ImageLazyLoad';
import SizesContainer from 'components/SizesContainer';
import ModifiersContainer from 'components/ModifiersContainer';
import DishesSwiper from 'components/DishesSwiper';
import AddToCartButton from 'components/AddToCartButton';

import cn from 'classnames';
import css from './OrderDishPopup.module.scss';

const closeScrollPositionValue = -20;
const scrollPositionTopPadding = 24;

type Props = {
  onClose: () => void;
  dishId?: number;
  noRecommendedDishes?: boolean;
  isTemporarilyHidden?: boolean;
};

const OrderDishPopup = ({ onClose, dishId, noRecommendedDishes = false, isTemporarilyHidden = false }: Props) => {
  const dispatch = useAppThunkDispatch();
  const menuConfig = useAppSelector(selectMenuConfig);
  const floatingPanelRef = useRef<FloatingPanelRef>(null);
  const floatingContentRef = useRef<HTMLDivElement | null>(null);
  const mainInfoContainerRef = useRef<HTMLDivElement | null>(null);
  const [anchors, setAnchors] = useState([closeScrollPositionValue]);
  const maxContainerHeightRef = useRef<number>(0);
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  const { height: windowHeight } = useSizes();

  const [mainInfoContainerHeight, setMainInfoContainerHeight] = useState(0);
  const previewDishes = useAppSelector(selectPreviewDishes);
  const stopList = useAppSelector(selectStopList);

  const currentDish = useAppSelector(getDishByIdSelector(dishId || 0));
  const defaultPrice = useDefaultPrice(currentDish);
  const { orderItem, itemCost, orderItemId, setItemSize, setItemModifiers } = useOrderItem(currentDish);

  const handleSetOverlayOpacity = useCallback((scrollPosition: number) => {
    const scrollValue = scrollPosition > 0 ? scrollPosition : 0;
    setOverlayOpacity(scrollValue / maxContainerHeightRef.current);
  }, []);

  const closeButtonClickHandler = useCallback(() => {
    floatingPanelRef.current?.setHeight(closeScrollPositionValue);
  }, []);

  const onHeightChangeHandler = useCallback(
    (scrollPosition: number) => {
      handleSetOverlayOpacity(scrollPosition);
      if (scrollPosition <= closeScrollPositionValue && !isTemporarilyHidden) {
        onClose();
      }
    },
    [handleSetOverlayOpacity, onClose, isTemporarilyHidden]
  );

  const recommendedDishes = useMemo(() => {
    if (!currentDish || currentDish.recommendedProductIds.length === 0) {
      return [];
    }

    const dishes = currentDish.recommendedProductIds.map((id) => previewDishes[id]);

    return dishes.filter((item) => item && !stopList.includes(item.id));
  }, [currentDish, previewDishes, stopList]);

  useEffect(() => {
    if (dishId && !currentDish) {
      dispatch(fetchDish(+dishId!));
    }
  }, [currentDish, dishId, dispatch]);

  const setMainInfoContainerHeightHandler = useCallback(() => {
    setMainInfoContainerHeight(mainInfoContainerRef.current?.scrollHeight || 0);
  }, []);

  useLayoutEffect(() => {
    setMainInfoContainerHeightHandler();
  }, [setMainInfoContainerHeightHandler, currentDish, orderItem]);

  useLayoutEffect(() => {
    if (isTemporarilyHidden) {
      floatingPanelRef.current?.setHeight(closeScrollPositionValue);
    }
  }, [isTemporarilyHidden]);

  const recalculateContainerHeight = useCallback(() => {
    if (currentDish && orderItem && mainInfoContainerHeight && !isTemporarilyHidden) {
      const contentHeight = floatingContentRef.current?.scrollHeight || 0;

      const resultAnchors = [closeScrollPositionValue];
      const maxHeight = window.innerHeight - scrollPositionTopPadding;

      if (contentHeight > maxHeight) {
        resultAnchors.push(maxHeight);
        setAnchors(resultAnchors);
        maxContainerHeightRef.current = maxHeight;
        setTimeout(() => floatingPanelRef.current?.setHeight(maxHeight), 0);
      } else {
        resultAnchors.push(contentHeight);
        setAnchors(resultAnchors);
        maxContainerHeightRef.current = contentHeight;
        setTimeout(() => floatingPanelRef.current?.setHeight(contentHeight), 0);
      }
    }
  }, [currentDish, isTemporarilyHidden, mainInfoContainerHeight, orderItem]);

  useLayoutEffect(() => {
    recalculateContainerHeight();
  }, [recalculateContainerHeight]);

  useEffect(() => {
    const timer = setTimeout(() => {
      recalculateContainerHeight();
    }, 0);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight]);

  const onRecommendedDishOpen = useCallback(
    (id: number) => {
      dispatch(setRecommendedDishIdForOrderPopup(id));
    },
    [dispatch]
  );

  const memoizedAddToCartButton = useMemo(() => {
    if (!orderItem) {
      return null;
    }

    return <AddToCartButton dish={{ ...orderItem, id: orderItemId }} cost={itemCost} />;
  }, [itemCost, orderItem, orderItemId]);

  return (
    <div>
      {currentDish && orderItem && (
        <>
          <div
            style={{
              opacity: overlayOpacity
            }}
            className={`${css.backgroundOverlay} ${currentDish && css.backgroundOverlayActive}`}
            onClick={closeButtonClickHandler}
          />
          <FloatingPanel
            ref={floatingPanelRef}
            onHeightChange={onHeightChangeHandler}
            anchors={anchors}
            className={css.floatingPanel}
            data-name={`orderPopup-${dishId}`}
          >
            {menuConfig.menuIsImageShown && <Button onClick={closeButtonClickHandler} className={css.closeButton} />}
            <div ref={floatingContentRef} className={css.container}>
              {menuConfig.menuIsImageShown && (
                <div className={css.imageContainer}>
                  <ImageLazyLoad image={currentDish?.image} />
                </div>
              )}

              <div
                className={cn(css.infoContainer, {
                  [css.infoContainerWithImage]: menuConfig.menuIsImageShown
                })}
              >
                {currentDish.ingredients && <p className={css.ingredients}>{currentDish.ingredients}</p>}

                {currentDish.description && (
                  <p className={css.description} data-name="orderItemDescription">
                    {currentDish.description}
                  </p>
                )}

                {currentDish.sizes.length > 1 && (
                  <div className="mb-24">
                    <SizesContainer
                      items={[...currentDish.sizes].sort((a, b) => a.price - b.price)}
                      onChange={setItemSize}
                    />
                  </div>
                )}

                {currentDish.modifierIds.length > 0 && (
                  <div className="mb-24">
                    <ModifiersContainer items={currentDish.modifierIds} onChange={setItemModifiers} />
                  </div>
                )}

                {!noRecommendedDishes && recommendedDishes.length > 0 && (
                  <div className={css.recommendedDishesContainer}>
                    <h2 className={`${css.dishSectionTitle} pl-24 pr-24`}>Хорошее сочетание</h2>
                    <DishesSwiper items={recommendedDishes} onDishClick={onRecommendedDishOpen} />
                  </div>
                )}
              </div>
              <div
                style={{
                  height: mainInfoContainerHeight
                }}
              />
            </div>
            <div ref={mainInfoContainerRef} className={css.mainInfoContainer}>
              <h1 className={css.title} data-name="orderItemName">
                {currentDish.name}
              </h1>

              <div className={css.header}>
                <span className={css.price} data-name="orderItemPrice">
                  {defaultPrice} ₽
                </span>
                <span className={css.weight} data-name="orderItemWeight">
                  {currentDish.weight}
                </span>
              </div>
              {memoizedAddToCartButton}
              <SafeArea position="bottom" />
            </div>
          </FloatingPanel>
        </>
      )}
    </div>
  );
};

export default memo(OrderDishPopup);
