import React from 'react';
import { DotLoading } from 'antd-mobile';

import css from './Preloader.module.scss';

const Preloader = () => (
  <div className={css.container}>
    <DotLoading />
  </div>
);

export default Preloader;
