import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchOrder, updateOrder, clearOrder, setOrder } from './actions';

const initialState: InitialState = {
  order: {
    items: []
  },
  loading: false,
  loaded: false
};

const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOrder, (state, action) => {
      state.order = { ...state.order, items: action.payload };
    })
    .addCase(fetchOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchOrder.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    })
    .addCase(fetchOrder.fulfilled, (state) => {
      state.loading = false;
      state.loaded = true;
    })
    .addCase(updateOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateOrder.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    })
    .addCase(clearOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(clearOrder.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    });
});

export default cartReducer;
