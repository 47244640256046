import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { DishGrid, LoadingPromoPage, NavBar, PageNotFound } from 'components';
import { selectStopList } from 'redux/stopList/selectors';
import { selectMenuLoading, selectPointName, selectPreviewDishes } from 'redux/menu/selectors';
import { useAppSelector, useScrollTopOnFirstRender } from '../../utils/hooks';
import { selectLoadingState, selectPromos } from '../../redux/promo/selectors';

import css from './PromoPage.module.scss';
import { ScrollProvider } from '../../utils/hooks/useScrollContext';

const PromoPage = () => {
  const pointName = useAppSelector(selectPointName);
  const { promoId: promoStringId } = useParams();
  const { byId } = useSelector(selectPromos);
  const { loaded: isPromoLoaded, error } = useSelector(selectLoadingState);
  const { loaded: isMenuLoaded } = useSelector(selectMenuLoading);
  const stopList = useAppSelector(selectStopList);

  const promoId = parseInt(promoStringId || 'NaN', 10);

  useScrollTopOnFirstRender([promoId]);

  const promo = useMemo(() => {
    if (isPromoLoaded) {
      return byId[promoId];
    }

    return undefined;
  }, [isPromoLoaded, byId, promoId]);

  const previewDishes = useAppSelector(selectPreviewDishes);

  const promoDishes = useMemo(() => {
    if (!promo || !isMenuLoaded) {
      return [];
    }

    const dishes = promo.productIds.map((id) => previewDishes[id]);

    return dishes.filter((item) => !stopList.includes(item.id));
  }, [previewDishes, promo, isMenuLoaded, stopList]);

  if (error) {
    return <PageNotFound />;
  }

  if (!isPromoLoaded) {
    return <LoadingPromoPage />;
  }

  return (
    <ScrollProvider>
      <div className={css.promoPageContainer}>
        <Helmet>
          <title>{isPromoLoaded ? pointName : `${promo!.name} - ${pointName}`}</title>
        </Helmet>
        <div className={css.promoNavContainer}>
          <NavBar />
        </div>

        <div className={css.promoInfoContainer}>
          <h1 className={css.promoTitle}>{promo?.name}</h1>

          {promo?.description && <div className={css.promoDescription}>{promo!.description}</div>}
          {promoDishes.length > 0 && (
            <div className="mt-32">
              <h2 className={css.promoSectionTitle}>Акционные товары:</h2>
              <DishGrid items={promoDishes} promoId={promoId} />
            </div>
          )}
        </div>
      </div>
    </ScrollProvider>
  );
};

export default PromoPage;
