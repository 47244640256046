import { modifiersApi } from 'api';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { FlatModifiersMap } from 'entities/modifiers';
import { handleError } from 'utils/axios';
import { getPointSlug } from 'utils/decorators';

const pointSlug = getPointSlug();

type FetchAllModifiersResponse = {
  modifiers: FlatModifiersMap;
};

const setModifiers = createAction<FetchAllModifiersResponse>('menu/setModifiers');

const fetchAllModifiers = createAsyncThunk<
  void,
  undefined,
  {
    rejectValue?: string;
  }
>('menu/fetchAllModifiers', async (_, { rejectWithValue, dispatch }) => {
  try {
    const {
      data: { modifiers }
    } = await modifiersApi.getAllModifiers(pointSlug);

    const modifiersMap = modifiers.reduce((acc, item) => ({ ...acc, [item.id]: item }), {} as FlatModifiersMap);

    localStorage.setItem(`modifiers-${pointSlug}`, JSON.stringify({ modifiers: modifiersMap }));

    await dispatch(setModifiers({ modifiers: modifiersMap }));

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchAllModifiers, setModifiers };
