import React from 'react';
import { Button, ErrorBlock } from 'antd-mobile';

import { useAppThunkDispatch } from 'utils/hooks';
import { setSearchString } from 'redux/search/actions';
import { ReactComponent as DishNotFoundImage } from 'images/dish-not-found.svg';

import css from './DishNotFound.module.scss';

const DishNotFound = () => {
  const dispatch = useAppThunkDispatch();

  const onCancelButtonClick = () => {
    dispatch(setSearchString(''));
  };

  return (
    <div className={css.container} data-name="dishNotFound">
      <ErrorBlock
        status="empty"
        title="Ничего не нашли"
        description="Возможно, стоит упростить поиск или очистить его"
        fullPage
        image={<DishNotFoundImage />}
        className={css.notFoundDishError}
      >
        <Button fill="none" onClick={onCancelButtonClick} className={css.cancelButton}>
          Очистить поиск
        </Button>
      </ErrorBlock>
    </div>
  );
};

export default DishNotFound;
