import React, { useMemo, memo } from 'react';
import { Grid } from 'antd-mobile';

import { useAppSelector } from 'utils/hooks';
import { selectStopList } from 'redux/stopList/selectors';
import { CategoryItem } from '../../entities/menu';
import DishItem from '../DishItem';
import { selectMenuConfig } from '../../redux/menu/selectors';

type Props = {
  items: CategoryItem[];
  promoId?: number;
};

const DishGrid = ({ items, promoId }: Props) => {
  const menuConfig = useAppSelector(selectMenuConfig);

  const stopList = useAppSelector(selectStopList);
  const dishList = useMemo(
    () =>
      items.map((item) => (
        <DishItem item={item} isDisabled={stopList.includes(item.id)} key={item.id} promoId={promoId} />
      )),
    [items, stopList, promoId]
  );

  const columnsCount = useMemo(() => {
    if (menuConfig.menuView === 'list' || menuConfig.menuGridColsCount === 1) {
      return 1;
    }

    return 2;
  }, [menuConfig.menuGridColsCount, menuConfig.menuView]);

  const gapSize = useMemo(() => {
    if (menuConfig.menuView === 'list') {
      return 8;
    }

    return 4;
  }, [menuConfig.menuView]);

  return (
    <Grid columns={columnsCount} gap={gapSize}>
      {dishList}
    </Grid>
  );
};

export default memo(DishGrid);
