import React, { useCallback, useState } from 'react';

import { selectOrder } from 'redux/cart/selectors';
import { useAppSelector } from 'utils/hooks';
import { breakPriceIntoDigits } from 'utils/decorators';
import useCart from 'utils/hooks/useCart';
import { selectInitialDataLoaded } from 'redux/commonSelectors';
import { EmptyOrder, NavBar, Preloader } from 'components';
import CartItem from './CartItem/CartItem';
import ClearCartButtonContainer from './ClearCartButtonContainer';
import ClearCartPopupContainer from './ClearCartPopupContainer';

import css from './CartPage.module.scss';

const CartPage = () => {
  const isInitialDataLoaded = useAppSelector(selectInitialDataLoaded);

  const order = useAppSelector(selectOrder);

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  const { totalCost, cartItemsState } = useCart();

  const onOpenClearCartPopup = useCallback(() => {
    setIsPopupVisible(true);
  }, []);

  const onCloseClearCartPopup = useCallback(() => {
    setIsPopupVisible(false);
  }, []);

  if (!isInitialDataLoaded) {
    return <Preloader />;
  }

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.navBarContainer}>
          <NavBar />
        </div>

        <ClearCartButtonContainer onButtonClick={onOpenClearCartPopup} />
      </div>

      {order.items.length === 0 ? (
        <EmptyOrder />
      ) : (
        <>
          <h1 className={css.title}>Заказ</h1>
          <ul className={css.orderList}>
            {order.items.map((item) => {
              const { isDisabled, cost } = cartItemsState[item.id!];

              return (
                <li key={item.id} className={css.orderItem}>
                  <CartItem dish={item} isDisabled={isDisabled} cost={cost} />
                </li>
              );
            })}
          </ul>
          <div className={css.footer}>
            <h2 className={css.cost} data-name="totalCost">{`${breakPriceIntoDigits(totalCost)} ₽`}</h2>
            {/* Hidden until it will be customizable */}
            {/* <span className={css.duration}>12-20 минут</span> */}
            <p className={css.text}>Покажите заказ официанту</p>
          </div>
        </>
      )}

      <ClearCartPopupContainer isVisible={isPopupVisible} onClose={onCloseClearCartPopup} />
    </div>
  );
};

export default CartPage;
