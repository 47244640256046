import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { setOrder } from 'redux/cart/actions';
import { CartItem } from 'entities/cart';
import { selectMenuLoading, selectPreviewDishes } from 'redux/menu/selectors';
import { selectModifiers, selectModifiersLoading } from 'redux/modifiers/selectors';
import { useAppThunkDispatch, useAppSelector } from './redux';

const useOrderItemAvailability = () => {
  const dispatch = useAppThunkDispatch();
  const { pointSlug } = useParams();
  const { loaded: isMenuLoaded } = useAppSelector(selectMenuLoading);
  const { loaded: isModifiersLoaded } = useAppSelector(selectModifiersLoading);
  const dishList = useAppSelector(selectPreviewDishes);
  const modifiers = useAppSelector(selectModifiers);
  const prevOrder = JSON.parse(localStorage.getItem(`order-${pointSlug}`) || '[]');

  useEffect(() => {
    if (isMenuLoaded && isModifiersLoaded) {
      const dishesIds = Object.keys(dishList).map((key) => +key);
      const modifiersIds = Object.keys(modifiers).map((key) => +key);
      let newOrder = [...prevOrder];

      newOrder = newOrder.filter((item: CartItem) => {
        const unavailableModifier = item.modifiers.find((id) => !modifiersIds.includes(id));

        return dishesIds.includes(item.dishId) && !unavailableModifier;
      });

      if (prevOrder.length !== newOrder.length) {
        localStorage.setItem(`order-${pointSlug}`, JSON.stringify(newOrder));
        dispatch(setOrder(newOrder));
      }
    }
  }, [pointSlug, dispatch, prevOrder, dishList, isMenuLoaded, isModifiersLoaded, modifiers]);
};

export default useOrderItemAvailability;
