import React from 'react';

import { CategoryItem } from 'entities/menu';

import { useSelector } from 'react-redux';
import ItemWithImage from './ItemWithImage';
import ItemWithoutImage from './ItemWithoutImage';

import { selectMenuConfig } from '../../redux/menu/selectors';

type Props = {
  item: CategoryItem;
};

const RecommendationItem = ({ item }: Props) => {
  const menuConfig = useSelector(selectMenuConfig);

  if (!menuConfig.menuIsImageShown) {
    return <ItemWithoutImage item={item} />;
  }

  return <ItemWithImage item={item} />;
};
export default React.memo(RecommendationItem);
