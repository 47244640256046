import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Stepper } from 'antd-mobile';

import { CartItem } from 'entities/cart';
import { updateOrder } from 'redux/cart/actions';
import { useAppThunkDispatch } from 'utils/hooks';
import { selectOrder } from 'redux/cart/selectors';
import { selectStopList } from 'redux/stopList/selectors';
import { toggleSuccessPopup } from 'redux/layout/actions';

import css from './AddToCartButton.module.scss';

type Props = {
  cost: number;
  dish: CartItem;
};

const errorMessage = 'Не удалось добавить блюдо в козину';

const AddToCartButton = ({ cost, dish }: Props) => {
  const { pointSlug } = useParams();
  const dispatch = useAppThunkDispatch();
  const order = useSelector(selectOrder);
  const stopList = useSelector(selectStopList);
  const sameDishIndex = order.items.findIndex((item: CartItem) => item.id === dish.id);
  const [error, setError] = useState<string>('');

  const isDishDisabled = useMemo(() => stopList.includes(dish.dishId), [stopList, dish]);

  const onButtonClick = async () => {
    try {
      await dispatch(updateOrder({ ...dish, counter: 1 })).unwrap();
      dispatch(toggleSuccessPopup(true));
    } catch (err: any) {
      setError(errorMessage);
    }
  };

  const onCounterClick = (value: number) => {
    dispatch(updateOrder({ ...dish, counter: value }));
  };

  return (
    <div className={css.container}>
      {sameDishIndex !== -1 && !isDishDisabled ? (
        <div className={css.counterContainer} data-name="addToCartStepper">
          <Stepper
            value={order.items[sameDishIndex].counter}
            min={0}
            className={css.counter}
            onChange={onCounterClick}
          />
          <Link to={`/${pointSlug}/cart`} className="link-no-styles full-width">
            <Button className={css.button} onClick={onButtonClick} disabled>
              Добавлено
            </Button>
          </Link>
        </div>
      ) : (
        <Button className={css.button} onClick={onButtonClick} disabled={isDishDisabled} data-name="addToCartButton">
          {isDishDisabled ? 'Появится позже' : `+ Добавить за ${cost}₽`}
        </Button>
      )}
      {error && <span>{error}</span>}
    </div>
  );
};

export default AddToCartButton;
