import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Divider } from 'antd-mobile';
import { GiftOutlined } from '@ant-design/icons';
import cn from 'classnames';

import {
  getDishByIdSelector,
  selectMenuConfig,
  selectMenuLoading,
  selectPointName,
  selectPreviewDishes
} from 'redux/menu/selectors';
import { selectStopList, selectStopListLoading } from 'redux/stopList/selectors';
import { selectModifiersLoading } from 'redux/modifiers/selectors';
import {
  useAppSelector,
  useAppThunkDispatch,
  useDefaultPrice,
  useOrderItem,
  useScrollTopOnFirstRender
} from 'utils/hooks';
import {
  AddToCartButton,
  CartButton,
  DishesSwiper,
  ImageLazyLoad,
  LoadingDishPage,
  ModifiersContainer,
  NavBar,
  PageNotFound,
  PromosSwiper,
  SizesContainer
} from 'components';
import { getPromoByIdSelector, selectLoadingState } from 'redux/promo/selectors';
import { fetchDish } from 'redux/menu/actions';
import { setRecommendedDishIdForOrderPopup } from 'redux/layout/actions';

import css from './DishPage.module.scss';

type LocationState = {
  promoId?: number;
};

const DishPage = () => {
  const dispatch = useAppThunkDispatch();
  const menuConfig = useAppSelector(selectMenuConfig);
  const location = useLocation();
  const { dishId } = useParams();

  useScrollTopOnFirstRender([dishId]);

  const [loadingDishError, setLoadingDishError] = useState(false);
  const { loaded: isMenuLoaded } = useSelector(selectMenuLoading);
  const { loaded: isPromoLoaded } = useSelector(selectLoadingState);
  const { loaded: isStopListLoaded } = useAppSelector(selectStopListLoading);
  const { loaded: isModifiersLoaded } = useAppSelector(selectModifiersLoading);
  const previewDishes = useAppSelector(selectPreviewDishes);

  const currentDish = useAppSelector(getDishByIdSelector(+dishId!));
  const defaultPrice = useDefaultPrice(currentDish);
  const { orderItem, itemCost, orderItemId, setItemSize, setItemModifiers } = useOrderItem(currentDish);

  const pointName = useAppSelector(selectPointName);
  const stopList = useAppSelector(selectStopList);
  const { promoId: cameFromPromoId } = (location.state || {}) as LocationState;
  const promo = useAppSelector(getPromoByIdSelector(+cameFromPromoId!));

  useEffect(() => {
    if (!currentDish) {
      dispatch(fetchDish(+dishId!))
        .unwrap()
        .catch(() => {
          setLoadingDishError(true);
        });
    }
  }, [currentDish, dishId, dispatch]);

  const isDishDisabled = useMemo(() => stopList.includes(currentDish?.id), [stopList, currentDish]);

  const recommendedDishes = useMemo(() => {
    if (!currentDish || currentDish.recommendedProductIds.length === 0 || !isMenuLoaded) {
      return [];
    }

    const dishes = currentDish.recommendedProductIds.map((id) => previewDishes[id]);

    if (!isStopListLoaded) {
      return dishes;
    }

    return dishes.filter((item) => !stopList.includes(item.id));
  }, [currentDish, previewDishes, isStopListLoaded, stopList, isMenuLoaded]);

  const dishSizes = useMemo(() => {
    if (currentDish) {
      return [...currentDish.sizes].sort((a, b) => a.price - b.price);
    }
    return [];
  }, [currentDish]);

  const onRecommendedDishOpen = useCallback(
    (id: number) => {
      dispatch(setRecommendedDishIdForOrderPopup(id));
    },
    [dispatch]
  );

  const memoizedAddToCartButton = useMemo(() => {
    if (!orderItem) {
      return null;
    }

    return (
      <div className={css.submitButtonContainer}>
        <AddToCartButton dish={{ ...orderItem, id: orderItemId }} cost={itemCost} />
      </div>
    );
  }, [itemCost, orderItem, orderItemId]);

  if (loadingDishError) {
    return <PageNotFound />;
  }

  if (!currentDish || !orderItem || !isMenuLoaded || !isModifiersLoaded) {
    return <LoadingDishPage />;
  }

  return (
    <div className={`${css.dishPageContainer} ${isDishDisabled && css.disabled}`}>
      <Helmet>
        <title>{`${currentDish?.name} - ${pointName}`}</title>
      </Helmet>
      <div
        className={cn({
          [css.dishImageContainer]: menuConfig.menuIsImageShown,
          [css.headerContainer]: !menuConfig.menuIsImageShown
        })}
      >
        <div className={css.navBarContainer}>
          <NavBar />
        </div>

        {menuConfig.menuIsImageShown && <ImageLazyLoad image={currentDish.image} />}

        <div className={css.cartButtonContainer}>
          <CartButton />
        </div>
      </div>

      {promo && (
        <div className={css.previewPromo}>
          <div className={`${css.previewPromoTitle} ${css.previewPromoText}`}>
            <GiftOutlined className={css.previewPromoTitleIcon} />
            <span className={`${css.previewPromoText}`}>{promo.name}</span>
          </div>

          {promo.description && (
            <div className={css.previewPromoDescriptionContainer}>
              <p className={`${css.previewPromoDescription} ${css.previewPromoText}`}>{promo.description}</p>
            </div>
          )}
        </div>
      )}

      <div className={css.dishInfoContainer}>
        <h1 className={css.dishTitle} data-name="dishName">
          {currentDish.name}
        </h1>

        <div className={css.dishHeader}>
          <span className={css.dishPrice} data-name="dishPrice">
            {isDishDisabled ? 'Появится позже' : `${defaultPrice} ₽`}
          </span>
          <span className={css.dishWeight} data-name="dishWeight">
            {currentDish.weight}
          </span>
        </div>

        {currentDish.ingredients && <p className={css.dishIngredients}>{currentDish.ingredients}</p>}

        <Divider />

        {currentDish.description && (
          <p className={css.dishDescription} data-name="dishDescription">
            {currentDish.description}
          </p>
        )}

        {currentDish.sizes.length > 1 && (
          <div className="mb-24">
            <SizesContainer items={dishSizes} onChange={setItemSize} />
          </div>
        )}

        {currentDish.modifierIds.length > 0 && (
          <div className="mb-24">
            <ModifiersContainer items={currentDish.modifierIds} onChange={setItemModifiers} />
          </div>
        )}
      </div>

      {isPromoLoaded && currentDish.promoIds.length > 0 && !cameFromPromoId && (
        <>
          <h2 className={css.dishSectionTitle}>Участвует в акциях</h2>
          <div className={css.promo}>
            <PromosSwiper itemsIds={currentDish.promoIds} />
          </div>
        </>
      )}

      {isMenuLoaded && recommendedDishes.length > 0 && (
        <div className="mb-16">
          <h2 className={`${css.dishSectionTitle} pl-24 pr-24`}>Хорошее сочетание</h2>
          <DishesSwiper items={recommendedDishes} onDishClick={onRecommendedDishOpen} />
        </div>
      )}

      {memoizedAddToCartButton}
    </div>
  );
};

export default DishPage;
