import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.modifiers;

const selectModifiers = createSelector([selectState], ({ items }) => items);

const selectModifiersLoading = createSelector([selectState], ({ loaded, loading }) => ({ loading, loaded }));

export { selectModifiers, selectModifiersLoading };
