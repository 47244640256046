import React from 'react';
import { Grid, Skeleton } from 'antd-mobile';

import css from './LoadingDishList.module.scss';

const LoadingDishList = () => {
  const items = ['1i', '2i', '3i', '4i'];

  return (
    <Grid columns={2} gap={4}>
      {items.map((item) => (
        <Grid.Item key={item}>
          <Skeleton animated className={css.item} />
        </Grid.Item>
      ))}
    </Grid>
  );
};

export default LoadingDishList;
