import React from 'react';
import { Skeleton } from 'antd-mobile';

import LoadingDishList from '../LoadingDishList';

import css from './LoadingPromoPage.module.scss';

const LoadingPromoPage = () => (
  <div className={css.container}>
    <Skeleton animated className={css.navbar} />

    <div className={css.info}>
      <Skeleton.Title animated className={css.infoTitle} />

      <div className={css.paragraph2}>
        <Skeleton.Paragraph lineCount={3} animated />
      </div>

      <Skeleton animated className={css.listTitle} />
      <div className="mt-8">
        <LoadingDishList />
      </div>
    </div>
  </div>
);

export default LoadingPromoPage;
