import React, { useCallback } from 'react';
import { Radio } from 'antd-mobile';

import { Size } from 'entities/menu';
import { formatPrice } from 'utils/decorators';
import { ReactComponent as RadioIcon } from 'images/radio-icon.svg';
import { ReactComponent as RadioCheckedIcon } from 'images/radio-checked-icon.svg';

import css from './SizesContainer.module.scss';

type Props = {
  items: Size[];
  onChange: (id: number) => void;
};

const getRadioIcon = (checked: boolean) => {
  if (checked) {
    return <RadioCheckedIcon />;
  }

  return <RadioIcon />;
};

const SizesContainer = ({ items, onChange }: Props) => {
  const onChangeHandler = useCallback((id: string | number) => onChange(+id), [onChange]);

  return (
    <div className={css.optionsContainer} data-name="sizeList">
      <h3 className={css.optionListTitle}>Размеры</h3>

      <Radio.Group defaultValue={items.find((size) => size.isDefault)?.id} onChange={onChangeHandler}>
        {items.map((size) => (
          <Radio
            key={size.id}
            value={size.id}
            className={css.radioItem}
            icon={getRadioIcon}
            data-name={`sizeItem-${size.id}`}
          >
            <h4 className={css.optionName}>{size.name}</h4>
            {size && <span className={css.optionPrice}>{`${formatPrice(size.price)} ₽`}</span>}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default SizesContainer;
