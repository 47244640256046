import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.menu;

const selectMenu = createSelector([selectState], (state) => state.categories);

const selectCurrentMenu = createSelector([selectState], (state) => state.currentDishes);

const selectPointName = createSelector([selectState], (state) => state.name);

const selectMenuLoading = createSelector([selectState], (state) => ({ loading: state.loading, loaded: state.loaded }));

const selectCategoryMenuById = createSelector([selectMenu, (state, categoryId) => categoryId], (categories, id) =>
  categories?.find((item) => item.categoryId === id)
);

type CategoryMenuByIdSelector = (state: RootState) => ReturnType<typeof selectCategoryMenuById>;

const categorySelectors: Record<string, CategoryMenuByIdSelector> = {};

export const getCategoryMenuByIdSelector = (id: number): CategoryMenuByIdSelector => {
  const existingSelector = categorySelectors[id];

  if (existingSelector) {
    return existingSelector;
  }

  // reselect knows nothing about second argument
  // @ts-ignore
  const newSelectorFunction = (state: RootState) => selectCategoryMenuById(state, id);
  categorySelectors[id] = newSelectorFunction;
  return newSelectorFunction;
};

const selectDishById = createSelector(
  [selectState, (state, dishId) => dishId],
  (state, dishId) => state.loadedDishes[dishId]
);

type DishByIdSelector = (state: RootState) => ReturnType<typeof selectDishById>;

const dishSelectors: Record<number, DishByIdSelector> = {};

export const getDishByIdSelector = (dishId: number): DishByIdSelector => {
  const existingSelector = dishSelectors[dishId];

  if (existingSelector) {
    return existingSelector;
  }

  // reselect knows nothing about second argument
  // @ts-ignore
  const newSelectorFunction = (state: RootState) => selectDishById(state, dishId);
  dishSelectors[dishId] = newSelectorFunction;
  return newSelectorFunction;
};

export const selectPreviewDishes = createSelector([selectState], (state) => state.previewDishes);

export const selectMenuConfig = createSelector([selectState], (state) => state.config);

export { selectPointName, selectMenuLoading, selectMenu, selectCurrentMenu, selectCategoryMenuById, selectDishById };
