import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CapsuleTabs } from 'antd-mobile';

import { useSelector } from 'react-redux';
import css from './CategoriesTabs.module.scss';
import { Category } from '../../../entities/menu';
import { selectMenuLoading } from '../../../redux/menu/selectors';

type Props = {
  activeKey: string;
  onChange: (activeKey: string, withScroll: boolean) => void;
  scrollIsActive: boolean;
  categories: Category[];
};

const CategoriesTabs = ({ activeKey, onChange, scrollIsActive, categories }: Props) => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const [firstScrollIndex, setFirstScrollIndex] = useState('notScrolled');
  const { loaded: isMenuLoaded } = useSelector(selectMenuLoading);

  const tabContainerRef = React.useRef<HTMLDivElement>(null);

  const activeKeyRef = useRef(activeKey);

  useEffect(() => {
    activeKeyRef.current = activeKey;
  }, [activeKey]);

  const recalcActiveTab = useCallback(() => {
    const categoriesNodes = document.querySelectorAll('div[data-category^="category-"]');
    let initialActiveTab: string = '';
    for (let i = 0; i < categoriesNodes.length; i += 1) {
      const categoryNode = categoriesNodes[categoriesNodes.length - i - 1] as HTMLDivElement;

      const resultOffsetTop = (categoryNode.parentNode as HTMLDivElement).offsetTop + categoryNode.offsetTop;

      if (window.scrollY >= resultOffsetTop) {
        initialActiveTab = categoryNode.dataset.category!;
        break;
      }
    }
    if (!initialActiveTab && categories[0]) {
      initialActiveTab = `category-${categories[0].categoryId}`;
    }

    if (activeKeyRef.current !== initialActiveTab) {
      onChange(initialActiveTab, false);
    }
  }, [categories, onChange]);

  useEffect(() => {
    setTimeout(() => {
      recalcActiveTab();
    }, 10);
  }, [recalcActiveTab]);

  const onScroll = useCallback(() => {
    const scrolledBy = window.scrollY > tabContainerRef.current!.offsetTop;
    if (firstScrollIndex !== 'scrolled') {
      setFirstScrollIndex('scrolled');
    }

    if (scrolledBy && !showStickyHeader) {
      setShowStickyHeader(true);
    } else if (!scrolledBy && showStickyHeader) {
      setShowStickyHeader(false);
    }

    if (!scrollIsActive) {
      recalcActiveTab();
    }
  }, [firstScrollIndex, showStickyHeader, scrollIsActive, recalcActiveTab]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  // This two effects needed to trigger onScroll function to recalculate tabs position
  // And only because if you reload the page, there is no initial 'scroll' event if the page is scrolled down
  useLayoutEffect(() => {
    setFirstScrollIndex('initialScroll');
    // Only for the first render
    // eslint-disable-next-line
  }, [isMenuLoaded]);

  useEffect(() => {
    if (firstScrollIndex !== 'notScrolled') {
      onScroll();
    }
    // eslint-disable-next-line
  }, [firstScrollIndex]);

  return (
    <div className={css.container} ref={tabContainerRef}>
      <div className={`${showStickyHeader ? css.stickyContainer : css.relative}`}>
        <CapsuleTabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          key={String(firstScrollIndex)}
          onChange={(key) => {
            if (!scrollIsActive) {
              onChange(key, true);
            }
          }}
        >
          {categories.map((category) => (
            <CapsuleTabs.Tab title={category.categoryName} key={`category-${category.categoryId}`} />
          ))}
        </CapsuleTabs>
      </div>
    </div>
  );
};

export default CategoriesTabs;
