import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { fetchMenu } from 'redux/menu/actions';
import { fetchStopList } from 'redux/stopList/actions';
import { fetchAllModifiers } from 'redux/modifiers/actions';
import { fetchAllPromos } from 'redux/promo/actions';
import { fetchOrder } from 'redux/cart/actions';
import { setDishIdForOrderPopup, setRecommendedDishIdForOrderPopup, toggleSuccessPopup } from 'redux/layout/actions';
import {
  selectDishIdForOrderPopup,
  selectRecommendedDishIdForOrderPopup,
  selectSuccessPopup
} from 'redux/layout/selectors';
import { useAppSelector, useAppThunkDispatch, useLocalStorageListening, useOrderItemAvailability } from 'utils/hooks';
import { Error, ErrorBoundary, OrderDishPopup, SuccessPopup } from 'components';

import css from './MainLayout.module.scss';

const errorMessage = 'Ресторан не найден или что-то пошло не так, попробуйте еще раз';

const MainLayout = () => {
  const dispatch = useAppThunkDispatch();
  const location = useLocation();
  const [requestError, setRequestError] = useState('');
  const orderPopupDishId = useAppSelector<number | undefined>(selectDishIdForOrderPopup);
  const orderPopupRecommendedDishId = useAppSelector<number | undefined>(selectRecommendedDishIdForOrderPopup);
  const isSuccessPopupOpened = useAppSelector(selectSuccessPopup);
  const { pointSlug } = useParams();

  useLocalStorageListening();
  useOrderItemAvailability();

  const onSuccessPopupClose = useCallback(() => {
    dispatch(toggleSuccessPopup(false));
  }, [dispatch]);

  const onOrderDishPopupClose = useCallback(() => {
    dispatch(setDishIdForOrderPopup(undefined));
  }, [dispatch]);

  const onOrderRecommendedDishPopupClose = useCallback(() => {
    dispatch(setRecommendedDishIdForOrderPopup(undefined));
  }, [dispatch]);

  const loadInitialData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(fetchMenu()).unwrap(),
        dispatch(fetchStopList()).unwrap(),
        dispatch(fetchAllPromos()).unwrap(),
        dispatch(fetchAllModifiers()).unwrap(),
        dispatch(fetchOrder()).unwrap()
      ]);
    } catch (error: any) {
      setRequestError(errorMessage);
    }
  }, [dispatch]);

  const gotoMainPage = useCallback(() => {
    document.location.href = `/${pointSlug}`;
  }, [pointSlug]);

  const repeatRequest = () => {
    setRequestError('');
    loadInitialData();
  };

  useEffect(() => {
    onOrderDishPopupClose();
    onOrderRecommendedDishPopupClose();
    onSuccessPopupClose();
  }, [location.pathname, onOrderDishPopupClose, onOrderRecommendedDishPopupClose, onSuccessPopupClose]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const UnexpectedErrorComponent = useCallback(
    () => (
      <Error
        message="Что-то пошло не так"
        buttonText="Вернуться на главную"
        onErrorButtonClick={gotoMainPage}
        dataName="error-page"
      />
    ),
    [gotoMainPage]
  );

  if (requestError) {
    return (
      <Error
        message={requestError}
        buttonText="Попробовать снова"
        onErrorButtonClick={repeatRequest}
        dataName="error-page"
      />
    );
  }

  return (
    <ErrorBoundary ErrorComponent={UnexpectedErrorComponent}>
      <div className={css.page} id="MainLayout">
        <Outlet />

        <OrderDishPopup
          dishId={orderPopupDishId}
          onClose={onOrderDishPopupClose}
          isTemporarilyHidden={!!orderPopupRecommendedDishId}
        />
        <OrderDishPopup
          dishId={orderPopupRecommendedDishId}
          onClose={onOrderRecommendedDishPopupClose}
          noRecommendedDishes
        />
        <SuccessPopup isVisible={isSuccessPopupOpened} onCancel={onSuccessPopupClose} />
      </div>
    </ErrorBoundary>
  );
};

export default MainLayout;
