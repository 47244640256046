import { useMemo } from 'react';

import { Dish, CategoryItem } from 'entities/menu';
import { formatPrice } from 'utils/decorators';

const useDefaultPrice = (dish?: Dish | CategoryItem) =>
  useMemo(() => {
    if (!dish) {
      return 0;
    }

    const { price } = dish.sizes.find((size) => size.isDefault)!;
    return formatPrice(price);
  }, [dish]);

export default useDefaultPrice;
