import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from 'antd-mobile';
import { CategoryItem } from '../../entities/menu';
import DishGrid from '../DishGrid';
import { useScrollContext } from '../../utils/hooks/useScrollContext';
import { useAppSelector } from '../../utils/hooks';
import { selectItemGridRowHeight } from '../../redux/layout/selectors';

import css from './DishList.module.scss';
import { selectMenuConfig } from '../../redux/menu/selectors';

type Props = {
  items: CategoryItem[];
  index: number;
};

const VirtualizedChunks = ({ items, index }: Props) => {
  const { y } = useScrollContext();
  const menuConfig = useAppSelector(selectMenuConfig);
  const itemRowHeight = useAppSelector(selectItemGridRowHeight);

  const [isVisible, setIsVisible] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const alreadyInCalculating = React.useRef(false);

  const delayedFunction = useCallback(
    () =>
      new Promise<boolean>((resolve) => {
        if (containerRef.current) {
          const boundRect = containerRef.current.getBoundingClientRect();

          const isItemVisibleFromTop = window.innerHeight * 2 > boundRect.top && boundRect.top > 0;

          if (isItemVisibleFromTop) {
            resolve(true);
          }

          const isItemVisibleFromBottom =
            boundRect.bottom - window.innerHeight < 0 && boundRect.bottom + window.innerHeight * 2 > 0;

          if (isItemVisibleFromBottom) {
            resolve(true);
          }

          const isItemBiggerThanScreen = boundRect.height > window.innerHeight;

          const isItemOnScreen = isItemBiggerThanScreen
            ? boundRect.top < 0 && boundRect.bottom > 0
            : isItemVisibleFromTop || isItemVisibleFromBottom;

          if (isItemOnScreen) {
            resolve(true);
          }

          resolve(false);
        }

        resolve(false);
      }),
    []
  );

  useEffect(() => {
    if (!alreadyInCalculating.current) {
      alreadyInCalculating.current = true;
      setTimeout(async () => {
        const resultState = await delayedFunction();
        setIsVisible(resultState);
        alreadyInCalculating.current = false;
      }, 150);
    }
    // eslint-disable-next-line
  }, [index, y]);

  const memoizedVirtualItemStyles = useMemo(
    () => ({
      height: itemRowHeight
    }),
    [itemRowHeight]
  );

  const columnsCount = useMemo(() => {
    if (menuConfig.menuView === 'list' || menuConfig.menuGridColsCount === 1) {
      return 1;
    }

    return 2;
  }, [menuConfig.menuGridColsCount, menuConfig.menuView]);

  const gapSize = useMemo(() => {
    if (menuConfig.menuView === 'list') {
      return 8;
    }

    return 4;
  }, [menuConfig.menuView]);

  const memoizedContent = useMemo(() => {
    if (isVisible) {
      return <DishGrid items={items} />;
    }

    return (
      <Grid columns={columnsCount} gap={gapSize}>
        {items.map((item) => (
          <div
            data-name={`dishItem-${item.id}`}
            style={memoizedVirtualItemStyles}
            className={css.virtualizedItem}
            key={item.id}
          />
        ))}
      </Grid>
    );
  }, [columnsCount, gapSize, isVisible, items, memoizedVirtualItemStyles]);

  return <div ref={containerRef}>{memoizedContent}</div>;
};

export default VirtualizedChunks;
