const formatPrice = (price: number): string => {
  const stringifiedPrice = String(price);
  const rest = stringifiedPrice.slice(stringifiedPrice.length - 2);
  const front = stringifiedPrice.slice(0, stringifiedPrice.length - 2);

  if (price === 0) {
    return '0';
  }

  if (parseInt(rest, 10) === 0) {
    return front;
  }

  return `${front}.${rest}`;
};

// TODO Regarding prices need to think about using browser's Intl module
// console.log(new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(12234));
const breakPriceIntoDigits = (price: number): string => {
  const chars = price.toString().split('');

  const priceWithSpaces = chars.reduceRight((acc, char, i) => {
    const spaceOrNothing = (price.toString().length - i) % 3 === 0 ? ' ' : '';
    return spaceOrNothing + char + acc;
  }, '');

  return priceWithSpaces[0] === ' ' ? priceWithSpaces.slice(1) : priceWithSpaces;
};

const getPointSlug = () => window.location.pathname.split('/')[1];

export { formatPrice, breakPriceIntoDigits, getPointSlug };
