import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchAllPromos } from './actions';

const initialState: InitialState = {
  loaded: false,
  loading: false,
  error: false,
  items: {
    ids: [],
    byId: {}
  }
};

const promoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllPromos.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAllPromos.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
      state.loaded = true;
    })
    .addCase(fetchAllPromos.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = true;
    });
});

export default promoReducer;
