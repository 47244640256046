import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectMenuConfig } from 'redux/menu/selectors';
import OpenOrderPopupButton from 'components/OpenOrderPopupButton';
import { setDishIdForOrderPopup } from 'redux/layout/actions';
import { useAppThunkDispatch, useDefaultPrice } from 'utils/hooks';
import { CategoryItem } from 'entities/menu';

import cn from 'classnames';
import css from './ListDishItem.module.scss';

type Props = {
  item: CategoryItem;
  isDisabled: boolean;
  promoId?: number;
};

const ListDishItem = ({ item, isDisabled, promoId }: Props) => {
  const dispatch = useAppThunkDispatch();
  const { menuIsDescriptionShown } = useSelector(selectMenuConfig);
  const defaultPrice = useDefaultPrice(item);

  const onOpenOrderPopupButtonClick = () => {
    dispatch(setDishIdForOrderPopup(item.id));
  };

  return (
    <Link
      to={`../dish/${item.id}`}
      state={{ promoId }}
      className={cn(css.link, {
        [css.disabled]: isDisabled
      })}
    >
      <div
        className={cn(css.item, css.container, {
          [css.containerWithDescription]: menuIsDescriptionShown,
          [css.itemDisabled]: isDisabled
        })}
        data-name={`dishItem-${item.id}`}
      >
        {isDisabled && <div className={css.disabledOverlay} />}
        <div className={css.itemWrapper}>
          <div
            className={cn(css.infoContainer, {
              [css.infoContainerWithDescription]: menuIsDescriptionShown
            })}
          >
            <div>
              <h3 className={css.title} data-name="dishItemName">
                {item.name}
              </h3>

              {menuIsDescriptionShown && (
                <div className={css.description} data-name="dishItemDescription">
                  {item.description}
                </div>
              )}
            </div>

            <div className={css.subInfo}>
              <span className={css.price} data-name="dishItemPrice">
                {isDisabled ? 'Будет позже' : `${defaultPrice}₽`}
              </span>
              <span className={css.weight} data-name="dishItemWeight">
                {item.weight}
              </span>
            </div>
          </div>
          <div>{!isDisabled && <OpenOrderPopupButton onClick={onOpenOrderPopupButtonClick} />}</div>
        </div>
      </div>
    </Link>
  );
};
export default React.memo(ListDishItem);
