import React from 'react';
import { Skeleton } from 'antd-mobile';

import css from './LoadingRecommendations.module.scss';

const LoadingRecommendations = () => {
  const items = ['1i', '2i', '3i', '4i'];

  const dishList = items.map((item: string) => (
    <div className={css.swiperSlide} key={item}>
      <Skeleton animated className={css.item} />
    </div>
  ));

  return (
    <div className={css.swiperWrapper}>
      <div className={css.swiper}>{dishList}</div>
    </div>
  );
};

export default LoadingRecommendations;
