import React from 'react';
import { Stepper } from 'antd-mobile';

import { CartItem as CartItemType } from 'entities/cart';
import { selectPreviewDishes } from 'redux/menu/selectors';
import { selectModifiers } from 'redux/modifiers/selectors';
import { updateOrder } from 'redux/cart/actions';
import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { ImageLazyLoad } from 'components';

import cn from 'classnames';
import css from './CartItem.module.scss';

type Props = {
  dish: CartItemType;
  isDisabled: boolean;
  cost: number;
};

const CartItem = ({ dish, isDisabled, cost }: Props) => {
  const dispatch = useAppThunkDispatch();
  const modifiers = useAppSelector(selectModifiers);
  const previewDishes = useAppSelector(selectPreviewDishes);

  const currentPreviewDish = previewDishes[dish.dishId];
  const currentSize = currentPreviewDish?.sizes.find((item) => item.id === dish.size)!;

  const onCounterClick = (value: number) => {
    dispatch(updateOrder({ ...dish, counter: value }));
  };

  if (!currentPreviewDish) {
    return null;
  }

  return (
    <div
      className={cn(css.container, {
        [css.itemDisabled]: isDisabled
      })}
      data-name={`cartItem-${dish.id}`}
    >
      <div className={css.imageContainer}>
        <ImageLazyLoad image={currentPreviewDish.image} className={css.image} />
      </div>
      <div>
        <h3 className={css.title} data-name="cartItemName">
          {currentPreviewDish.name}
        </h3>
        <div className="mb-8">
          <span className={css.price} data-name="cartItemPrice">
            {isDisabled ? 'Появится позже' : `${cost}₽`}
          </span>
          {!isDisabled && <span className={css.weight}>{currentPreviewDish.weight}</span>}
        </div>
        <span className={css.size} data-name="cartItemSize">{`Размер: ${currentSize.name}`}</span>
        <ul className={css.modifierList}>
          {dish.modifiers.map((modifierId) => {
            if (
              !Object.keys(modifiers)
                .map((id) => +id)
                .includes(modifierId)
            ) {
              return null;
            }
            return (
              <li key={modifierId} className={css.modifierItem} data-name="cartItemModifier">
                {modifiers[modifierId].name}
              </li>
            );
          })}
        </ul>
        <Stepper
          value={dish.counter}
          min={0}
          className={css.counter}
          onChange={onCounterClick}
          disabled={isDisabled}
          data-name="cartItemStepper"
        />
      </div>
    </div>
  );
};

export default CartItem;
