import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

type ScrollPosition = {
  x: number;
  y: number;
};

const ScrollContext = createContext<ScrollPosition>({ x: 0, y: 0 });

type Props = {
  children: React.ReactNode;
};

const ScrollProvider = ({ children }: Props) => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  const scrollDelayedRef = useRef(false);

  const handleScroll = useCallback(() => {
    if (!scrollDelayedRef.current) {
      scrollDelayedRef.current = true;
      setTimeout(() => {
        setScrollPosition({ x: window.scrollX, y: window.scrollY });
        scrollDelayedRef.current = false;
      }, 100);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return <ScrollContext.Provider value={scrollPosition}>{children}</ScrollContext.Provider>;
};

const useScrollContext = () => React.useContext(ScrollContext);

export { ScrollProvider, useScrollContext };
