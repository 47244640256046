import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const selectState = (state: RootState) => state.promo;

const selectLoadingState = createSelector(selectState, ({ loading, loaded, error }) => ({
  loading,
  loaded,
  error
}));

const selectPromos = createSelector(selectState, ({ items }) => items);

const selectPromoById = createSelector([selectPromos, (state, promoId) => promoId], (promos, id) => promos?.byId[id]);

type PromoByIdSelector = (state: RootState) => ReturnType<typeof selectPromoById>;

const promoSelectors: Record<string, PromoByIdSelector> = {};

export const getPromoByIdSelector = (id: number): PromoByIdSelector => {
  const existingSelector = promoSelectors[id];

  if (existingSelector) {
    return existingSelector;
  }

  // reselect knows nothing about second argument
  // @ts-ignore
  const newSelectorFunction = (state: RootState) => selectPromoById(state, id);

  promoSelectors[id] = newSelectorFunction;
  return newSelectorFunction;
};

export { selectLoadingState, selectPromos, selectPromoById };
