import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchAllModifiers, setModifiers } from './actions';

const initialState: InitialState = {
  items: [],
  loading: false,
  loaded: false
};

const modifiersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setModifiers, (state, action) => {
      state.items = action.payload.modifiers;
    })
    .addCase(fetchAllModifiers.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAllModifiers.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    })
    .addCase(fetchAllModifiers.fulfilled, (state) => {
      state.loading = false;
      state.loaded = true;
    });
});

export default modifiersReducer;
