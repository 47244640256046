import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { setOrder } from 'redux/cart/actions';
import { setMenu } from 'redux/menu/actions';
import { setModifiers } from 'redux/modifiers/actions';
import { setStopList } from 'redux/stopList/actions';
import { useAppThunkDispatch } from './redux';

const useLocalStorageListening = () => {
  const dispatch = useAppThunkDispatch();
  const { pointSlug } = useParams();

  useEffect(() => {
    const setDataToStore = (event: StorageEvent) => {
      const { newValue, key } = event;

      if (key === `order-${pointSlug}`) {
        dispatch(setOrder(JSON.parse(newValue || '[]')));
      }

      if (key === `menu-${pointSlug}`) {
        dispatch(setMenu(JSON.parse(newValue!)));
      }

      if (key === `modifiers-${pointSlug}`) {
        dispatch(setModifiers(JSON.parse(newValue!)));
      }

      if (key === `stopList-${pointSlug}`) {
        dispatch(setStopList(JSON.parse(newValue!)));
      }
    };

    window.addEventListener('storage', setDataToStore);

    return () => {
      window.removeEventListener('storage', setDataToStore);
    };
  }, [pointSlug, dispatch]);
};

export default useLocalStorageListening;
