import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectMenuConfig } from 'redux/menu/selectors';
import { useAppThunkDispatch, useDefaultPrice } from 'utils/hooks';
import { CategoryItem } from 'entities/menu';
import ImageLazyLoad from 'components/ImageLazyLoad';
import OpenOrderPopupButton from 'components/OpenOrderPopupButton';
import { setDishIdForOrderPopup } from 'redux/layout/actions';

import cn from 'classnames';
import css from './CardDishItem.module.scss';

type Props = {
  item: CategoryItem;
  isDisabled: boolean;
  promoId?: number;
};

const CardDishItem = ({ item, isDisabled, promoId }: Props) => {
  const dispatch = useAppThunkDispatch();
  const { menuIsImageShown, menuIsDescriptionShown } = useSelector(selectMenuConfig);
  const defaultPrice = useDefaultPrice(item);

  const onOpenOrderPopupButtonClick = () => {
    dispatch(setDishIdForOrderPopup(item.id));
  };

  return (
    <Link to={`../dish/${item.id}`} state={{ promoId }} className={`${css.link} ${isDisabled && css.disabled}`}>
      <div
        className={cn(css.item, css.container, {
          [css.itemDisabled]: isDisabled,
          [css.containerWithDescription]: menuIsDescriptionShown && !menuIsImageShown,
          [css.containerWithImage]: menuIsImageShown && !menuIsDescriptionShown,
          [css.containerWithImageWithDescription]: menuIsDescriptionShown && menuIsImageShown
        })}
        data-name={`dishItem-${item.id}`}
      >
        {menuIsImageShown && (
          <div className={css.imageContainer} data-name={`dishItemImage-${item.id}`}>
            <ImageLazyLoad image={item.image} />
          </div>
        )}
        <div
          className={cn(css.infoContainer, {
            [css.infoContainerWithDescription]: menuIsDescriptionShown
          })}
        >
          <div>
            <h3 className={css.title} data-name="dishItemName">
              {item.name}
            </h3>
            <span className={css.weight} data-name="dishItemWeight">
              {item.weight}
            </span>
            {menuIsDescriptionShown && (
              <div className={css.description} data-name="dishItemDescription">
                {item.description}
              </div>
            )}
          </div>

          <div className={css.bottomBlock}>
            <span className={css.price} data-name="dishItemPrice">
              {isDisabled ? 'Будет позже' : `${defaultPrice}₽`}
            </span>
            {!isDisabled && (
              <div className={css.buttonContainer}>
                <OpenOrderPopupButton onClick={onOpenOrderPopupButtonClick} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
export default React.memo(CardDishItem);
