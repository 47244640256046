import React, { Component, ReactNode, ElementType, ErrorInfo } from 'react';
import * as Sentry from '@sentry/react';

type Props = {
  children: ReactNode;
  ErrorComponent: ElementType;
};

class ErrorBoundary extends Component<
  Props,
  {
    hasError: boolean;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, {
      extra: {
        errorInfo
      }
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, ErrorComponent } = this.props;

    if (hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

export default ErrorBoundary;
