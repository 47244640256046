import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { Category } from 'entities/menu/index';
import { setCurrentDishes } from 'redux/menu/actions';
import { menuSearchIndex } from 'utils/menu';

import type { RootState } from 'redux/store';

type AsyncThunkConfig = {
  state: RootState;
};

const setSearchLoadingState = createAction<boolean>('search/setSearchLoadingState');

const searchDishesAction = createAsyncThunk<void, undefined, AsyncThunkConfig>(
  'search/searchDish',
  async (_, { getState, dispatch }) => {
    const store = getState();
    const { categories } = store.menu;
    const { string } = store.search;

    if (string.length === 0) {
      dispatch(setCurrentDishes(categories));
    }

    if (string.length > 2) {
      const index = menuSearchIndex.getIndex();

      const foundDishesIds = index.search(string);

      const foundDishes = categories.reduce((acc, category) => {
        const foundItems = category.items.filter((dish) => foundDishesIds.includes(dish.id));

        if (foundItems.length > 0) {
          return [...acc, { ...category, items: foundItems }];
        }

        return acc;
      }, [] as Category[]);

      await dispatch(setCurrentDishes(foundDishes));
    }
    dispatch(setSearchLoadingState(false));
  }
);
const setSearchString = createAction<string>('search/setSearchString');

export { searchDishesAction, setSearchString, setSearchLoadingState };
