import React from 'react';
import { Button } from 'antd-mobile';
import { PlusOutlined } from '@ant-design/icons';

import css from './OpenOrderPopupButton.module.scss';

type Props = {
  onClick: () => void;
};

const OpenOrderPopupButton = ({ onClick }: Props) => {
  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <div className={css.container}>
      <Button className={css.button} onClick={onButtonClick} data-name="openOrderPopupButton">
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default React.memo(OpenOrderPopupButton);
