import mock from './mockAdapter';
import { PromoItem } from '../../../entities/promo';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const promoList: {
  items: PromoItem[];
} = {
  items: [
    {
      id: 1,
      name: 'Промо продукт 1',
      description: 'Описание промо продукта 1',
      productIds: [],
      image: 'https://picsum.photos/200/300',
      createdAt: '2020-01-01T00:00:00.000Z'
    },
    {
      id: 2,
      name: 'Промо продукт 2',
      description: 'Описание промо продукта 2',
      productIds: [],
      image: 'https://picsum.photos/400/400',
      createdAt: '2020-01-01T00:00:00.000Z'
    }
  ]
};

// get all points
mock.onGet('/promos/public').reply(200, promoList);

mock.onGet(/\/promos\/\d+/).reply(200, {
  id: 1,
  name: 'Промо продукт 1',
  description: 'Описание промо продукта 1',
  productsIds: [11, 12, 13],
  image: 'https://picsum.photos/400/400'
});
