import { createSelector } from '@reduxjs/toolkit';

import { selectOrderLoading } from 'redux/cart/selectors';
import { selectStopListLoading } from 'redux/stopList/selectors';
import { selectModifiersLoading } from 'redux/modifiers/selectors';
import { selectMenuLoading } from 'redux/menu/selectors';

const selectInitialDataLoaded = createSelector(
  [selectModifiersLoading, selectMenuLoading, selectStopListLoading, selectOrderLoading],
  ({ loaded: isModifiersLoaded }, { loaded: isMenuLoaded }, { loaded: isStopListLoaded }, { loaded: isOrderLoaded }) =>
    isModifiersLoaded && isMenuLoaded && isStopListLoaded && isOrderLoaded
);

// eslint-disable-next-line import/prefer-default-export
export { selectInitialDataLoaded };
