import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { HomePage, DishPage, PromoPage, CartPage } from 'pages';
import { PageNotFound } from 'components';
import { MainLayout } from './layouts';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/:pointSlug" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="dish/:dishId" element={<DishPage />} />
        <Route path="promo/:promoId" element={<PromoPage />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
