import React, { useCallback } from 'react';
import { Checkbox } from 'antd-mobile';

import { selectModifiers } from 'redux/modifiers/selectors';
import { formatPrice } from 'utils/decorators';
import { useAppSelector } from 'utils/hooks';

import css from './ModifiersContainer.module.scss';

type Props = {
  items: number[];
  onChange: (ids: number[]) => void;
};

const ModifiersContainer = ({ items, onChange }: Props) => {
  const modifiers = useAppSelector(selectModifiers);

  const onChangeHandler = useCallback((ids: (string | number)[]) => onChange(ids.map((id) => +id)), [onChange]);

  return (
    <div className={css.optionsContainer} data-name="modifierList">
      <h3 className={css.optionListTitle}>Добавки</h3>
      <Checkbox.Group onChange={onChangeHandler}>
        {items.map((modifierId: number) => {
          if (!modifiers[modifierId]) {
            return null;
          }

          return (
            <Checkbox
              key={modifierId}
              value={modifierId}
              className={css.checkboxItem}
              data-name={`modifierItem-${modifierId}`}
            >
              <h4 className={css.optionName}>{modifiers[modifierId].name}</h4>
              {!!modifiers[modifierId].price && (
                <span className={css.optionPrice}>{`+ ${formatPrice(modifiers[modifierId].price)} ₽`}</span>
              )}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </div>
  );
};

export default ModifiersContainer;
