import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const selectState = (state: RootState) => state.layout;

const selectItemGridRowHeight = createSelector(selectState, ({ itemGridRowHeight }) => itemGridRowHeight);

const selectDishIdForOrderPopup = createSelector(selectState, ({ dishIdForOrderPopup }) => dishIdForOrderPopup);

const selectRecommendedDishIdForOrderPopup = createSelector(
  selectState,
  ({ recommendedDishIdForOrderPopup }) => recommendedDishIdForOrderPopup
);

const selectSuccessPopup = createSelector(selectState, ({ isSuccessPopupOpened }) => isSuccessPopupOpened);

export { selectItemGridRowHeight, selectDishIdForOrderPopup, selectRecommendedDishIdForOrderPopup, selectSuccessPopup };
