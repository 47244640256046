import { getPointSlug } from 'utils/decorators';
import { menuApi } from 'api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Category, MenuConfig, Dish, CategoryItem } from 'entities/menu';
import { handleError } from 'utils/axios';
import { menuSearchIndex } from 'utils/menu';

const pointSlug = getPointSlug();

const setCurrentDishes = createAction<Category[]>('menu/searchDish');

type SetMenuArgsType = {
  menu: Category[];
  menuConfig: MenuConfig;
};

type SetMenuReturnType = SetMenuArgsType & { preloadedDishes: Record<number, CategoryItem> };

const setMenu = createAsyncThunk<
  SetMenuReturnType,
  SetMenuArgsType,
  {
    rejectValue?: string;
  }
>('menu/setMenu', async (data, { rejectWithValue, dispatch }) => {
  try {
    menuSearchIndex.resetIndex();
    const index = menuSearchIndex.getIndex();

    const preloadedDishes = data.menu.reduce(
      (acc, category) => ({
        ...acc,
        ...category.items.reduce((acc2, dish) => {
          index.add(dish.id, `${dish.name} ${dish.description}`);

          return {
            ...acc2,
            [dish.id]: dish
          };
        }, {} as Record<number, CategoryItem>)
      }),
      {} as Record<number, CategoryItem>
    );

    await dispatch(setCurrentDishes(data.menu));

    return {
      menu: data.menu,
      preloadedDishes,
      menuConfig: data.menuConfig
    };
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

const fetchMenu = createAsyncThunk<
  { pointName: string },
  undefined,
  {
    rejectValue?: string;
  }
>('menu/fetchMenu', async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await menuApi.getMenu(pointSlug);

    localStorage.setItem(`menu-${pointSlug}`, JSON.stringify(data));

    await dispatch(setMenu({ menu: data.menu, menuConfig: data.menuConfig }));

    return { pointName: data.pointName };
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

const fetchDish = createAsyncThunk<
  Dish,
  number,
  {
    rejectValue?: string;
  }
>('menu/fetchDish', async (id, { rejectWithValue }) => {
  try {
    const { data } = await menuApi.getDish(id);

    return data;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(axiosError.response?.data.errorMessage);
    }
    return rejectWithValue(error?.message);
  }
});

export { fetchMenu, fetchDish, setMenu, setCurrentDishes };
