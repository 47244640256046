import React from 'react';
import { Skeleton } from 'antd-mobile';

import LoadingTabs from '../LoadingTabs';
import LoadingDishList from '../LoadingDishList';

import css from './LoadingMenu.module.scss';

const LoadingMenu = () => (
  <div className={css.container}>
    <div className={css.tabs}>
      <LoadingTabs />
    </div>

    <div className="pl-8 pr-8">
      <Skeleton.Title animated className={css.title} />
      <div className="mt-16">
        <LoadingDishList />
      </div>
    </div>
  </div>
);

export default LoadingMenu;
