import { formatPrice } from 'utils/decorators';
import { selectModifiers } from 'redux/modifiers/selectors';
import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect, useMemo } from 'react';

import { Dish } from 'entities/menu';
import { CartItem } from 'entities/cart';

const useOrderItem = (fullItem: Dish | null) => {
  const modifiers = useSelector(selectModifiers);
  const [orderItem, setOrderItem] = useState<null | CartItem>(null);

  useEffect(() => {
    if (fullItem) {
      setOrderItem({
        dishId: fullItem.id,
        name: fullItem.name,
        size: fullItem.sizes.find((item) => item.isDefault)!.id,
        modifiers: []
      });
    }
  }, [fullItem]);

  const orderItemId = useMemo(() => {
    if (!orderItem) {
      return '';
    }

    return `${orderItem.dishId}-${orderItem.size}${
      orderItem.modifiers.length > 0 ? `-${[...orderItem.modifiers].sort((a, b) => a - b).join('-')}` : ''
    }`;
  }, [orderItem]);

  const setItemSize = useCallback((id: number) => {
    setOrderItem((prevState) => ({ ...prevState!, size: id }));
  }, []);

  const setItemModifiers = useCallback((ids: number[]) => {
    setOrderItem((prevState) => ({ ...prevState!, modifiers: ids }));
  }, []);

  const itemCost = useMemo(() => {
    if (!fullItem || !orderItem) {
      return 0;
    }

    const dishPrice = fullItem.sizes.find((size) => size.id === orderItem.size)?.price;
    const modifiersCost = orderItem.modifiers.reduce((acc, modifierId) => acc + modifiers[modifierId].price, 0);

    return +formatPrice(dishPrice! + modifiersCost);
  }, [fullItem, modifiers, orderItem]);

  return { orderItem, orderItemId, itemCost, setItemSize, setItemModifiers };
};
export default useOrderItem;
