import React from 'react';
import { Skeleton } from 'antd-mobile';

import css from './LoadingImage.module.scss';

const LoadingImage = () => (
  <div className={css.skeletonPlaceholder}>
    <Skeleton animated className={css.customSkeleton} />
  </div>
);

export default LoadingImage;
