import mock from './mockAdapter';

const mockedStopList = {
  items: []
};

mock.onGet('/stop-list').reply((config) => {
  const id = config!.params!.pointId;

  if (id !== 111) {
    return [200, mockedStopList];
  }

  return [
    404,
    {
      errorMessage: 'Page Not Found'
    }
  ];
});
