import { configureStore, ThunkDispatch, Action } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import reducer from './reducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer,
  enhancers: [sentryReduxEnhancer]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export default store;
