import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchMenu, fetchDish, setMenu, setCurrentDishes } from './actions';

const initialState: InitialState = {
  name: '',
  categories: [],
  currentDishes: [],
  previewDishes: {},
  loadedDishes: {},
  loading: false,
  loaded: false,
  config: {
    menuView: 'card',
    menuGridColsCount: 2,
    menuIsImageShown: true,
    menuIsDescriptionShown: true
  }
};

const menuReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMenu.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchMenu.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    })
    .addCase(fetchMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.loaded = true;
      state.name = action.payload.pointName;
    })
    .addCase(setMenu.fulfilled, (state, action) => {
      state.categories = action.payload.menu;
      state.previewDishes = action.payload.preloadedDishes;
      state.config = action.payload.menuConfig;
    })
    .addCase(fetchDish.fulfilled, (state, action) => {
      state.loadedDishes[action.meta.arg] = action.payload;
    })
    .addCase(setCurrentDishes, (state, action) => {
      state.currentDishes = action.payload;
    });
});

export default menuReducer;
