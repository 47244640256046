import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import {
  setItemGridRowHeight,
  setDishIdForOrderPopup,
  setRecommendedDishIdForOrderPopup,
  toggleSuccessPopup
} from './actions';

const initialState: InitialState = {
  itemGridRowHeight: 238.27,
  dishIdForOrderPopup: undefined,
  recommendedDishIdForOrderPopup: undefined,
  isSuccessPopupOpened: false
};

const layoutReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setItemGridRowHeight, (state, action) => {
      state.itemGridRowHeight = action.payload;
    })
    .addCase(setDishIdForOrderPopup, (state, action) => {
      state.dishIdForOrderPopup = action.payload;
    })
    .addCase(setRecommendedDishIdForOrderPopup, (state, action) => {
      state.recommendedDishIdForOrderPopup = action.payload;
    })
    .addCase(toggleSuccessPopup, (state, action) => {
      state.isSuccessPopupOpened = action.payload;
    });
});

export default layoutReducer;
