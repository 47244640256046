import React, { useMemo } from 'react';
import { ActionSheet } from 'antd-mobile';
import type { Action } from 'antd-mobile/es/components/action-sheet';

import css from './ClearCartPopup.module.scss';

type Props = {
  isVisible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const ClearCartPopup = ({ isVisible, onSubmit, onCancel }: Props) => {
  const actions: Action[] = useMemo(
    () => [
      {
        text: 'Очистить',
        key: 'delete',
        danger: true,
        onClick: onSubmit
      },
      { text: 'Оставить', key: 'close', onClick: onCancel }
    ],
    [onCancel, onSubmit]
  );
  return (
    <ActionSheet
      extra="Ваша корзина будет полностью очищена"
      visible={isVisible}
      actions={actions}
      onClose={onCancel}
      className={css.popup}
      data-name="clearCartPopup"
      popupClassName={css.popupContainer}
    />
  );
};

export default ClearCartPopup;
